import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Modal, Spinner } from "react-bootstrap";
import firebase from "firebase";
import Select from "react-select";
import { toast } from "react-hot-toast";
import GroupCard from "../../Components/GroupCard";
import DeleteGroup from "../../Components/DeleteGroup";
import Upload from "../../../app/Images/upload.png";
import { baseUrl } from "../../../services/config";

const Groups = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupLists, setGroupLists] = useState([]);
  const [modelVisible, setModalvisible] = useState(false);
  const [groupName, setgroupName] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [userList, setuserList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imagePrivew, setImagePreview] = useState("");
  const authtoken = localStorage.getItem("userToken");

  useEffect(() => {
    getUsers();
    getGroups();
  }, []);

  const getUsers = () => {
    fetch(`${baseUrl}/admin/user`, {
     method: "GET",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
       Authorization: `Bearer ${authtoken}`,
       // 'X-Requested-With': 'XMLHttpRequest'
     },
   })
     .then((res) => res.json())
     .then((data) => {
       if (data.metadata.success) {
         let temparr = [];
         data.payload.forEach((user) => {
           temparr.push({
             label: user.name,
             value: user.id,
           });
         });
 
         setuserList(temparr);
       }
     })
     .catch((err) => {
       console.log(err);
     })


  
 
};

  const handleAdd = () => {
    if (groupName == "") {
      toast.error("Group name is required");
      return;
    }
    // if (selectedUsers.length == 0) {
    //   toast.error("Select group members");
    //   return;
    // }
    if (imagePrivew == "") {
      toast.error("Please Select Image");
      return;
    }
    setUploadLoading(true);
    const fdata = new FormData();
    fdata.append(`name`, groupName);
    fdata.append(`image`, imagePrivew);

    fetch(`${baseUrl}/admin/groups`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Group Created Successfully`);
        getGroups()
        setUploadLoading(false);
        setImagePreview('')
        setModalvisible(false);
      })
      .catch((err) => setUploadLoading(true));
  };

  const getGroups = () => {
    setisLoading(true);
    fetch(`${baseUrl}/admin/groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setisLoading(false);
        setGroupLists(data.payload.reverse());
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Card>
        <CardHeader title="Chat Groups">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setModalvisible(true)}
            >
              New Group
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {isLoading ? <Spinner animation="border" variant="primary" />:
          
          <div className="row">
            {groupLists.map((group) => (
              <GroupCard userList={userList} loadGroups={getGroups} item={group} />
            ))}
          </div>
          }

        </CardBody>
      </Card>

      <Modal
        centered
        animation
        onHide={() => setModalvisible(false)}
        show={modelVisible}
      >
        <Modal.Header>
          <Modal.Title>Create New Group</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12">
            <label>Group Name</label>
            <input
              onChange={(e) => setgroupName(e.target.value)}
              placeholder="Group Name"
              className="form-control"
            />
          </div>
          {/* <div className="col-md-12 mt-2">
            <label>Group Members</label>

            <Select
              isMulti
              name="colors"
              onChange={(e) => setSelectedUsers(e)}
              options={userList}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div> */}
          <div className="form-group row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="avatar-upload-input text-center">
                <img src={Upload} alt="img" />
                <h2>Upload Image</h2>
                <div className="avatar-edit-input">
                  <input
                    className="form-control"
                    name="file"
                    id="imagesupload"
                    type={"file"}
                    onChange={(e) => {
                      setImagePreview(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
            {imagePrivew ? (
              <img
                style={{
                  height: "100px",
                  width: "100px",
                  marginLeft: "12px",
                  marginTop: "3px",
                }}
                src={URL.createObjectURL(imagePrivew)}
              />
            ) : (
              ""
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => setModalvisible(false)}
            className="btn btn-danger"
          >
            Close
          </button>
          <button
            disabled={uploadLoading}
            onClick={handleAdd}
            className="btn btn-primary"
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Groups;
