import React, { useEffect, useRef, useState } from "react";
// import { ChatItem } from 'react-chat-elements'
import "./chat.scss";
import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Card, Image } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import BackupIcon from "@material-ui/icons/Backup";
import ImageIcon from "@material-ui/icons/Image";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { baseUrl } from "../../../services/config";
import { database, notificationUrl } from "../../../firebase/config";
import { Avatar } from "@material-ui/core";
import "./Chat2.scss";
import firebase from "firebase";
import { Spinner } from "react-bootstrap";
import { SendOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  CloseOutlined,
  FilePptOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FileOutlined,
} from "@ant-design/icons";
import SVG from "react-inlinesvg";

import { toast } from "react-hot-toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { deleteFile, uploadFiles } from "../../Components/Upload";
const useStyles = makeStyles((theme) => ({
  speedDial: {
    height: "20px",
  },
}));

const Chats = (props) => {
  const params = useParams();


  console.log("params", params)

  const { groupid } = params

  // const groupid = props?.location?.state?.item.id;

  const [messages, setMessages] = useState("");
  const [userList, setuserList] = useState([]);
  const [search, setSearch] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
  const [receiverDetails, setReceiverDetails] = useState({});
  const [loading, setloading] = useState(false);
  const [viewChat, setViewChat] = useState([]);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [vediouploadLoading, setvediouploadLoading] = useState(false);
  const [video, setVideo] = useState();
  const [image, setImage] = React.useState(null);
  const [uploadImage, setUploadImg] = useState();
  const [uploadCover, setUploadCover] = useState();
const [groupUsers,setGroupUsers]=useState([])
  const [groupName, setgroupName] = useState("");

  const authtoken = localStorage.getItem("userToken");
  const dummy = useRef();
  const chatRef = useRef();
  const messagesEndRef = useRef();

  useEffect(() => {
    getName()
    getAllUser();
  }, []);


  const getName = () => {
    fetch(`${baseUrl}/admin/groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }).then((res) => res.json())
      .then((data) => {
        const found = data.payload.find(item => item.id == groupid)
        setGroupUsers(found?.users)
        if (found) {
          setgroupName(found)
        } else {
          window.location.href = '/groups'
        }
      })
      .catch((err) => console.log(err));

  }

  useEffect(() => {
    receiveMessages();
  }, []);
  const scrollToLastMessage = () => {
    // messagesEndRef.current.scrollIntoView();
    chatRef.current.scrollIntoView({ behavior: "smooth" });
  };
  // useEffect(() => {
  //   window.scrollTo({
  //     behavior: 'smooth',
  //     top: document.documentElement.scrollHeight,
  //   });

  // }, [])

  const getColor = () => {
    let color = "";

    color = Math.floor(Math.random() * 16777215).toString(16);
    return color;
  };
  const getAllUser = async () => {
    setloading(true);
    await fetch(`${baseUrl}/admin/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false);

          let addingColor = data.payload;
          data.payload.map((item, index) => {
            item["color"] = Math.floor(Math.random() * 16777215).toString(16);
            addingColor[index] = item;
          });
          setuserList(addingColor);
          getReceiverUserData(addingColor[0]);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const actions = [
    { icon: <FileCopyIcon />, name: "File", type: "fileupload" },
    { icon: <ImageIcon />, name: "Image", type: "imageUpload" },
    { icon: <PlayCircleFilledIcon />, name: "Video", type: "vedioUpload" },
  ];
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dataSearch = userList.filter((item) => {
    let text2 = search.toLowerCase();
    const data = text2 ? item?.name?.toLowerCase().includes(text2) : true;

    return data;
  });

  const receiveMessages = async () => {
    firebase
      .database()
      .ref("groups")
      .child(groupid)
      .child("groupChat")
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          let arr = [];
          snapshot.forEach((snap) => {
            arr.push({
              sendBy: snap.child("senderUID").val(),
              receiveBy: snap.child("receiverUID").val(),
              message: snap.child("message").val(),
              type: snap.child("type").val(),
              url: snap.child("url").val(),
              senderUserName: snap.child("senderUserName").val(),
              thumbnail: snap.child("thumbnail").val(),
              timeStamp: snap.child("timeStamp").val(),
              filename: snap.child("filename").val(),
              deleteAbleId: snap.child("deleteAbleId").val(),
              thumbnailID: snap.child("thumbnailID").val(),
              key: snap.key,
            });
          });
          console.log(arr);
          setViewChat(arr);
          // setAllMessages(message);
        }
      });
  };

  const getReceiverUserData = (details) => {
    setReceiverDetails(details);
    database
      .ref(`/ChatMessages/${currentUser.id}/${details.id}`)
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          console.log(snapshot.val(), "thia is ");
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        console.log(arr);
        // setViewChat(arr);
      });
  };
  const chatmeessage = [
    {
      name: "Gulraiz",
      message: "hello world",
      type: "message",
    },
    {
      name: "Adim",
      message: "where",
      type: "message",
    },
    {
      name: "Gulraiz",
      message:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp3Fh0u2T0qF_PlBhSmrw1vUNj3pzCR1my9EClwSjRew&s",
      type: "image",
    },
    {
      name: "Gulraiz",
      message: "go",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello world",
      type: "message",
    },
    {
      name: "Adim",
      message:
        "https://img.rawpixel.com/private/static/images/website/2022-05/ns8230-image.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=b3961e17298745c0868eeef46211c3d0",
      type: "image",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Gulraiz",
      message: "hello hello hello",
      type: "message",
    },
    {
      name: "Adim",
      message: "acc.pdf",
      type: "file",
    },
  ];
  const handleImageUpload =async (img) => {
    // dummy.current.scrollIntoView();
    setUploadImg(img);
    setImageUploadLoading(true);
  const res=await uploadFiles(img)
if(res){
  if(res.metadata.success){
        sendData(messages, "image", "", "", "", res.payload.file.original_url,res.payload.id,'');
        notisender(groupName.name, "Admin Send A Image", res.payload.file.original_url);
        setImageUploadLoading(false);
        setUploadImg("");
  }else{
    toast.error('SomeThing went wrronge while uploading image')
  }
}

    // const storageRef = firebase.storage().ref();
    // storageRef
    //   .child(`chatimages/${img.name}`)
    //   .put(img)
    //   .then(async (snapshot) => {
    //     snapshot.ref.getDownloadURL().then((val) => {
    //       if (val) {
    //         setImageUploadLoading(false);
    //       }
    //       sendData(messages, "image", "", "", "", val);
    //       notisender(groupName.name, "Admin Send A Image", val);
    //       setUploadImg("");
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const handleFileUpload = async (file) => {
    setFileUploadLoading(true);
    const res=await uploadFiles(file)
    if(res){
      if(res.metadata.success){
            sendData(messages, "file", "", file.type, file.name, res.payload.file.original_url,res.payload.id,'');
            notisender(groupName.name, "Admin Send A File");
            setFileUploadLoading(false);
      }else{
        toast.error('SomeThing went wrronge while uploading file')
      }
    }
    // const storageRef = firebase.storage().ref();
    // storageRef
    //   .child(`chatimages/${file.name}`)
    //   .put(file)
    //   .then(async (snapshot) => {
    //     snapshot.ref.getDownloadURL().then((val) => {
    //       if (val) {
    //         setFileUploadLoading(false);
    //       }
    //       sendData(messages, "file", "", file.type, file.name, val,'');
    //       notisender(groupName.name, "Admin Send A File");
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const sendData = (data, type, thu, filetype, filename, url,deleteAbleId,thumbnailID) => {
    console.log(thu, "myThumbNail")
    console.log("chala");
    console.log(data);
    const pushkey = firebase
      .database()
      .ref("groups")
      .child(groupid)
      .child("groupChat")
      .push().key;

    console.log(pushkey);
    firebase
      .database()
      .ref("groups")
      .child(groupid)
      .child("groupChat")
      .push({
        senderUID: currentUser.id,
        receiverUID: currentUser.id,
        message: data,
        url: url,
        type: type,
        thumbnail: thu,
        filetype: filetype,
        filename: filename,
        userType: "admin",
        messageID: pushkey,
        senderUserName: "Admin",
        deleteAbleId:deleteAbleId,
        thumbnailID:thumbnailID,
        timeStamp: Date.now(),
      })
      .then(() => {
        // const sound = document.getElementById("sound");
        // sound.play();
        setMessages("");
        // dummy.current.scrollIntoView();
        chatRef.current.scrollIntoView();

        // database
        //   .ref("ChatMessages")
        //   .child(receiverDetails.id)
        //   .child(currentUser.id)
        //   .push({
        //     senderUID: currentUser.id,
        //     receiverUID: receiverDetails.id,
        //     message: data,
        //     type: type,
        //     thumbnail: thu,
        //     timeStamp: Date.now(),

        //   });
        // database
        //   .ref("ChatList")
        //   .child(receiverDetails.id)
        //   .child(currentUser.id)
        //   .set({
        //     senderUID: currentUser.id,
        //     receiverUID: receiverDetails.id,
        //     message: messages,
        //   });
        // database
        //   .ref("ChatList")
        //   .child(currentUser.id)
        //   .child(receiverDetails.id)
        //   .set({
        //     senderUID: currentUser.id,
        //     receiverUID: receiverDetails.id,
        //     message: messages,
        //   });

        // // push notification Work
        // const receiverToken = receiverDetails ? receiverDetails?.token : 'no token'
        // sendPushNotification(currentUser.id, "Send a new message", senderUserName, receiverUID, receiverUID, "chat_action", "hello", receiverToken)
      })
      .catch((err) => {
        // ToastAndroid.show(err, ToastAndroid.SHORT)
      });
  };
  const handleVedioUpload = async (vedio) => {
    setVideo(vedio);
    try {
      // get the frame at 1.5 seconds of the video file
      const cover = await getVideoCover(vedio, 1.5);
      // print out the result image blob
      const myFile = new File([cover], "thumbnail", { type: cover.type });
      console.log(myFile, "this is f");
      setUploadCover(myFile);
      setvediouploadLoading(true);
      chatRef.current.scrollIntoView();
      // const storageRef = firebase.storage().ref();
      // storageRef
      //   .child(`webThumbnail/${vedio.name}`)
      //   .put(myFile)
      //   .then(async (snapshot) => {
      //     snapshot.ref.getDownloadURL().then(async (thu) => {

      const thumbnail=await uploadFiles(myFile)
            console.log(thumbnail.payload.file.original_url, "this is thumbnail");
            let thu=thumbnail.payload.file.original_url
            if (thu) {
              console.log(thu, 'thumbnail')
              const res=await uploadFiles(vedio)
              if(res){
                if(res.metadata.success){
                      sendData(messages, "video", thu, "", "", res.payload.file.original_url,res.payload.id,thumbnail.payload.id);
                      notisender(groupName.name, "Admin Send A Video", thu);
                      setvediouploadLoading(false);
                }else{
                  toast.error('SomeThing went wrronge while uploading video')
                }
              }
              // const storageRef = firebase.storage().ref();
              // storageRef
              //   .child(`boardimages/${vedio.name}`)
              //   .put(vedio)
              //   .then(async (snapshot) => {
              //     snapshot.ref.getDownloadURL().then((val) => {
              //       if (val) {
              //         setvediouploadLoading(false);
              //       }
              //       sendData(messages, "video", thu, "", "", val,'');
              //       notisender(groupName.name, "Admin Send A Video", thu);
              //     });
              //   })
              //   .catch((err) => {
              //     console.log(err);
              //   });
            }
          // });
        // });

      setImage(cover);
    } catch (ex) {
      console.log("ERROR: ", ex);
      toast.error(ex);
    }
  };

  function getVideoCover(file, seekTo = 0.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }
  const sendMessages = async (e) => {
    e.preventDefault();
    if (messages == "") {
      console.log("no message");
      return;
    }
    console.log(receiverDetails);
    console.log(currentUser);
    sendData(messages, "message", "", "", "", "",'','');
    notisender(`Admin Send A Message in ${groupName.name}`, messages);
  };

  const dateconverter = (date) => {
    const myDate = new Date(date);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = myDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = monthNames[myDate.getMonth()];
    const year = myDate.getFullYear();
    const hours = myDate
      .getHours()
      .toString()
      .padStart(2, "0");
    const minutes = myDate
      .getMinutes()
      .toString()
      .padStart(2, "0");

    const formattedDate = `${hours}:${minutes}, ${month} ${day}, ${year}`;
    return formattedDate;
  };

  const handleDownload = (url) => {
    // fetch("https://firebasestorage.googleapis.com/v0/b/redhawk-2d1e0.appspot.com/o/chatimages%2Fportrait.pdf?alt=media&token=07359d55-9a27-4dfb-9e5d-5a0d6b1ba891")
    // .then(response => response.blob())
    // .then(blob => {
    //   const link = document.createElement('a');
    //   link.href = URL.createObjectURL(blob);
    //   link.download = 'filename.ext';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // })
    // .catch(error => {
    //   console.error('Error downloading file:', error);
    // });
  };

  console.log(viewChat);
  console.log(groupName);

  const notisender = (title, description, image) => {
    groupUsers &&
      groupUsers.map((item, i) => {
        const pushkey = firebase
          .database()
          .ref("notifications")
          .push().key;
        firebase
          .database()
          .ref("notifications")
          .child(item.id)
          .child(pushkey)
          .set({
            description: description,
            title: title,
            image: item?.image?.original_url || "",
            notificationId: pushkey,
            userName: item.name,
            userId: item.id,
            timeStamp: Date.now(),
          });
        item.device_ids.map((ids) => {
          const notification = {
            title: title,
            message: description,
            // image: image,
          };

          fetch(notificationUrl, {
            headers: {
              "Content-Type": "application/json",
              // Authorization:
              //   "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4",
            },
            method: "POST",
            body: JSON.stringify({
              ...notification,
              deviceToken: ids,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("response", data);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
  };
  const deleteThisMessage = (item) => {
       if(viewChat.length==1){
        setViewChat([])
       }
    if(item.deleteAbleId){
      deleteFile(item.deleteAbleId)
    }
    if(item.thumbnailID !=''){
      deleteFile(item.thumbnailID)
    }
    firebase
      .database()
      .ref("groups")
      .child(groupid)
      .child("groupChat")
      .child(item.key)
      .remove()
      .then(() => {
        toast.success("Deleted Successfully");
      });
  };
  return (
    // <div>
    //     <ChatItem
    //         avatar={'https://facebook.github.io/react/img/logo.svg'}
    //         alt={'Reactjs'}
    //         title={'Facebook'}
    //         subtitle={'What are you doing?'}
    //         date={new Date()}
    //         unread={0}
    //     />
    // </div>
    <>
      <div>
        <input
          onChange={(e) => handleFileUpload(e.target.files[0])}
          style={{ display: "none" }}
          className="btn btn-base"
          type="file"
          id="fileupload"
          accept=".pdf, .doc, .txt, .zip, .ppt, .xls, .xlsx, .docx, .pptx, .xlsb"
          multiple
        />
        <input
          onChange={(e) => handleVedioUpload(e.target.files[0])}
          style={{ display: "none" }}
          className="btn btn-base"
          type="file"
          id="vedioUpload"
          accept=".mp4"
          multiple
        />
        <input
          onChange={(e) => handleImageUpload(e.target.files[0])}
          style={{ display: "none" }}
          className="btn btn-base"
          type="file"
          id="imageUpload"
          accept=".png, .jpg, .jpeg"
          multiple
        />
      </div>
      <div class="containerr clearfix">
        {/* <div class="people-list" id="people-list">
          <div class="search">
            <input
              onChange={(e) => setSearch(e.target.value)}
              className="searchInput"
              type="text"
              placeholder="search"
            />
            <i class="fa fa-search"></i>
          </div>
          <ul class="list">
            {dataSearch.map((item) => (
              <li class="clearfix d-flex" onClick={() => getReceiverUserData(item)}>
                <Avatar style={{ backgroundColor: `#${item.color}`, }} alt={item?.name?.toUpperCase()} src="#" />
                <div class="about">
                  <div style={{ cursor: "pointer" }} class="name">
                    {item.name}
                  </div>
          
                </div>
              </li>
            ))}
          </ul>
        </div> */}

        <div class="chat">
          <div class="chat-header clearfix d-flex">
            <Avatar
              className="mt-2"
              style={{ backgroundColor: `#${receiverDetails.color}` }}
              alt={receiverDetails?.name?.toUpperCase()}
              src={groupName.image?.original_url}
            />

            <div class="chat-about">
              <div class="chat-with" style={{ color: "#FFFFFF" }}>
                {groupName.name}
              </div>
              <div class="chat-num-messages" style={{ color: "#FFFFFF" }}>
                ALready {viewChat.length} messages
              </div>
            </div>
            {/* <i class="fa fa-star"></i> */}
            <button
              style={{
                position: "absolute",
                right: "3%",
                top: "15px",
                borderRadius: "10px",
              }}
              onClick={() => scrollToLastMessage()}
            >
              Scroll Down
            </button>
          </div>

          <div class="chat-history">
            <ul>
              {viewChat?.map((item, index) => (
                <>
                  {item.sendBy == currentUser.id ? (
                    <>
                      <div>
                        {item.type == "message" ? (
                          <>
                            <div
                              class="comment other"
                              style={{
                                paddingBottom: "15px",
                                paddingTop: "15px",
                              }}
                            >
                              <Avatar
                                className="mt-2 chataimage"
                                style={{ backgroundColor: `#1e1e2d` }}
                                alt={currentUser?.name?.toUpperCase()}
                                src="#"
                              />
                              {/* <img className="chataimage" src="https://placekitten.com/700/700?image=12" alt=""/> */}
                              <div class="message-data">
                                <span
                                  class="message-data-name"
                                  style={{
                                    paddingLeft: "15px",
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {currentUser.name}
                                </span>
                                <span
                                  class="message-data-time"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {dateconverter(item.timeStamp)}
                                </span>
                              </div>
                              <div class="bubble1">{item.message}</div>
                              <span
                                title="delete"
                                style={{ cursor: "pointer" }}
                                className="svg-icon svg-icon-md svg-icon-danger ml-4"
                                onClick={() => {
                                  if (item.key) {
                                    deleteThisMessage(item);
                                  }
                                }}
                              >
                                <SVG
                                  title="delete"
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>

                              <div ref={chatRef}></div>
                            </div>
                            {index == viewChat.length - 1 && (
                              <div ref={messagesEndRef} />
                            )}
                          </>
                        ) : null}
                        {item.type == "file" ? (
                          <div class="" style={{ width: "100%" }}>
                            <div
                              class="comment other"
                              style={{
                                paddingBottom: "15px",
                                paddingTop: "15px",
                              }}
                            >
                              {/* <img className="chataimage" src="https://placekitten.com/700/700?image=12" alt=""/> */}
                              <Avatar
                                className="mt-2 chataimage"
                                style={{ backgroundColor: `#1e1e2d` }}
                                alt={currentUser?.name?.toUpperCase()}
                                src="#"
                              />
                              <div class="message-data">
                                <span
                                  class="message-data-name"
                                  style={{
                                    paddingLeft: "15px",
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  {currentUser.name}
                                </span>
                                <span
                                  class="message-data-time"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {dateconverter(item.timeStamp)}
                                </span>
                              </div>
                              <Card
                                size="small"
                                title={
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    style={{
                                      textDecoration: "none",
                                      listStyle: "none",
                                      color: "black",
                                    }}
                                  >
                                    {item?.filename?.length > 20
                                      ? item?.filename.substr(0, 20) + "..."
                                      : item?.filename}
                                  </a>
                                }
                                extra={
                                  <section className="d-flex">
                                    <span
                                      title="delete"
                                      style={{ cursor: "pointer" }}
                                      className="svg-icon svg-icon-md svg-icon-danger ml-4"
                                      onClick={() => {
                                        if (item.key) {
                                          deleteThisMessage(item);
                                        }
                                      }}
                                    >
                                      <SVG
                                        title="delete"
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/General/Trash.svg"
                                        )}
                                      />
                                    </span>
                                    <a href={item.url} target="_blank">
                                      <DownloadOutlined
                                        style={{ marginTop: "12px" }}
                                        className="text-primary mx-2"
                                        onClick={() =>
                                          handleDownload(item.message)
                                        }
                                      />
                                    </a>
                                  </section>
                                }
                                style={{
                                  width: 270,
                                  height: 155,
                                  marginTop: 13,
                                  marginLeft: 50,
                                }}
                              >
                                <div className="filesvg text-center">
                                  <a href={item.url} target="_blank">
                                    {item?.filename.replace(
                                      /.*(?=\.pptx)/,
                                      ""
                                    ) == ".pptx" ? (
                                      <FilePptOutlined
                                        style={{ fontSize: "7rem" }}
                                      />
                                    ) : item?.filename.replace(
                                      /.*(?=\.xlsx)/,
                                      ""
                                    ) == ".xlsx" ? (
                                      <FileExcelOutlined
                                        style={{
                                          fontSize: "7rem",
                                          color: "green",
                                        }}
                                      />
                                    ) : item?.filename.replace(
                                      /.*(?=\.pdf)/,
                                      ""
                                    ) == ".pdf" ? (
                                      <FilePdfOutlined
                                        style={{
                                          fontSize: "7rem",
                                          color: "red",
                                        }}
                                      />
                                    ) : item?.filename.replace(
                                      /.*(?=\.txt)/,
                                      ""
                                    ) == ".txt" ? (
                                      <FileTextOutlined
                                        style={{
                                          fontSize: "7rem",
                                          color: "black",
                                        }}
                                      />
                                    ) : (
                                      <FileOutlined
                                        style={{
                                          fontSize: "7rem",
                                          color: "black",
                                        }}
                                      />
                                    )}
                                  </a>

                                  {/* <iframe src={item.url} title={item.filename}></iframe> */}
                                </div>
                              </Card>
                            </div>
                            <div ref={chatRef}></div>
                          </div>
                        ) : null}

                        {item.type == "image" ? (
                          <>
                            <div class="comment other">
                              {/* <img className="chataimage" src="https://placekitten.com/700/700?image=12" alt=""/> */}
                              <Avatar
                                className="mt-2 chataimage"
                                style={{ backgroundColor: `#1e1e2d` }}
                                alt={currentUser?.name?.toUpperCase()}
                                src="#"
                              />
                              <div class="message-data">
                                <span
                                  class="message-data-name"
                                  style={{
                                    paddingLeft: "15px",
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {currentUser.name}
                                </span>
                                <span
                                  class="message-data-time"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {dateconverter(item.timeStamp)}
                                </span>
                              </div>
                              <div className="imagecontainercat">
                                <Image
                                  style={{ height: "100%", width: "100%" }}
                                  className="chatimg"
                                  src={item.url}
                                />
                                <CloseOutlined
                                  onClick={() => {
                                    if (item.key) {
                                      deleteThisMessage(item);
                                    }
                                  }}
                                  className="closeSvg"
                                />
                              </div>
                              {item.message !== null && item.message !== "" ? (
                                <div class="message my-message mt-3">
                                  {item.message}
                                </div>
                              ) : null}

                              <div ref={chatRef}></div>
                            </div>
                          </>
                        ) : null}

                        {item.type == "video" ? (
                          <>
                            <div class="comment other">
                              {/* <img className="chataimage" src="https://placekitten.com/700/700?image=12" alt=""/> */}
                              <Avatar
                                className="mt-2 chataimage"
                                style={{ backgroundColor: `#1e1e2d` }}
                                alt={currentUser?.name?.toUpperCase()}
                                src="#"
                              />
                              <div class="message-data">
                                <span
                                  class="message-data-name"
                                  style={{
                                    paddingLeft: "15px",
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {currentUser.name}
                                </span>
                                <span
                                  class="message-data-time"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {dateconverter(item.timeStamp)}
                                </span>
                              </div>
                              <div className="imagecontainercat">
                                <video
                                  controls
                                  height={200}
                                  width={270}
                                  autoPlay={false}
                                  src={item.url}
                                />
                                <CloseOutlined
                                  onClick={() => {
                                    if (item.key) {
                                      deleteThisMessage(item);
                                    }
                                  }}
                                  className="closeSvg2"
                                />
                              </div>
                              {item.message !== null && item.message !== "" ? (
                                <div class="message my-message mt-3">
                                  {item.message}
                                </div>
                              ) : null}

                              <div ref={chatRef}></div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div class="clearfix">
                      {/* <div class="message-data align-right">
                        <span class="message-data-time"></span>{" "}
                        &nbsp; &nbsp;
                        <span class="message-data-name">
                          {item.senderUserName}
                        </span>{" "}
                        <i class="fa fa-circle me"></i>
                      </div> */}
                      {item.type == "message" ? (
                        <>
                          <div
                            class="comment me"
                            style={{
                              paddingBottom: "15px",
                              paddingTop: "15px",
                            }}
                          >
                            <Avatar
                              className="mt-2 chataimage"
                              style={{ backgroundColor: `#${item.color}` }}
                              alt={item?.senderUserName?.toUpperCase()}
                              src="#"
                            />
                            {/* <img className="chataimage" src="https://placekitten.com/300/300?image=10" alt=""/> */}
                            <div class="message-data align-right">
                              <span class="message-data-time"></span> &nbsp;
                              &nbsp;
                              <span
                                style={{
                                  fontSize: "13px",
                                  paddingRight: "5px",
                                  fontWeight: "bolder",
                                }}
                                class="message-data-time"
                              >
                                {dateconverter(item.timeStamp)}
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  paddingRight: "15px",
                                }}
                                class="message-data-name"
                              >
                                {item.senderUserName}
                              </span>{" "}
                            </div>
                            <span
                              title="delete"
                              style={{ cursor: "pointer" }}
                              className="svg-icon svg-icon-md svg-icon-danger ml-4"
                              onClick={() => {
                                if (item.key) {
                                  deleteThisMessage(item);
                                }
                              }}
                            >
                              <SVG
                                title="delete"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                            <div class="bubble">{item.message}</div>
                          </div>
                        </>
                      ) : null}
                      {item.type == "file" ? (
                        <div class="float-right">
                          <div class="comment me">
                            {/* <img className="chataimage" src="https://placekitten.com/300/300?image=10" alt=""/> */}
                            <Avatar
                              className="mt-2 chataimage"
                              style={{ backgroundColor: `#${item.color}` }}
                              alt={item?.senderUserName?.toUpperCase()}
                              src="#"
                            />
                            <div class="message-data align-right">
                              <span class="message-data-time"></span> &nbsp;
                              &nbsp;
                              <span
                                style={{
                                  fontSize: "13px",
                                  paddingRight: "5px",
                                  fontWeight: "bolder",
                                }}
                                class="message-data-time"
                              >
                                {dateconverter(item.timeStamp)}
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  paddingRight: "15px",
                                }}
                                class="message-data-name"
                              >
                                {item.senderUserName}
                              </span>{" "}
                            </div>
                            <Card
                              size="small"
                              // title={
                              //   item?.
                              //     filename.length > 20
                              //     ? item?.filename.substr(0, 20) + "..."
                              //     : item?.filename
                              // }
                              extra={
                                <section className="d-flex">
                                  <span
                                    title="delete"
                                    style={{ cursor: "pointer" }}
                                    className="svg-icon svg-icon-md svg-icon-danger ml-4"
                                    onClick={() => {
                                      if (item.key) {
                                        deleteThisMessage(item);
                                      }
                                    }}
                                  >
                                    <SVG
                                      title="delete"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Trash.svg"
                                      )}
                                    />
                                  </span>
                                  <a href={item.url} target="_blank">
                                    <DownloadOutlined
                                      style={{ marginTop: "12px" }}
                                      className="text-primary mx-2"
                                    />
                                  </a>
                                </section>
                              }
                              style={{
                                width: 270,
                                height: 155,
                                marginTop: 13,
                                marginRight: 50,
                              }}
                            >
                              <div className="filesvg text-center">
                                {/* <span>{item?.filename}</span> */}
                                <a href={item.url} target="_blank">
                                  {item?.filename?.replace(
                                    /.*(?=\.pptx)/,
                                    ""
                                  ) == ".pptx" ? (
                                    <FilePptOutlined
                                      style={{ fontSize: "7rem" }}
                                    />
                                  ) : item?.filename?.replace(
                                    /.*(?=\.xlsx)/,
                                    ""
                                  ) == ".xlsx" ? (
                                    <FileExcelOutlined
                                      style={{
                                        fontSize: "7rem",
                                        color: "green",
                                      }}
                                    />
                                  ) : item?.filename?.replace(
                                    /.*(?=\.pdf)/,
                                    ""
                                  ) == ".pdf" ? (
                                    <FilePdfOutlined
                                      style={{ fontSize: "7rem", color: "red" }}
                                    />
                                  ) : item?.filename?.replace(
                                    /.*(?=\.txt)/,
                                    ""
                                  ) == ".txt" ? (
                                    <FileTextOutlined
                                      style={{
                                        fontSize: "7rem",
                                        color: "black",
                                      }}
                                    />
                                  ) : (
                                    <FileOutlined
                                      style={{
                                        fontSize: "7rem",
                                        color: "black",
                                      }}
                                    />
                                  )}
                                </a>

                                {/* <iframe src={item.url} title={item.filename}></iframe> */}
                              </div>
                            </Card>
                          </div>
                        </div>
                      ) : null}

                      {item.type == "image" ? (
                        <>
                          <div class="comment me">
                            {/* <img className="chataimage" src="https://placekitten.com/300/300?image=10" alt=""/> */}
                            <Avatar
                              className="mt-2 chataimage"
                              style={{ backgroundColor: `#${item.color}` }}
                              alt={item?.senderUserName?.toUpperCase()}
                              src="#"
                            />
                            <div class="message-data align-right">
                              <span class="message-data-time"></span> &nbsp;
                              &nbsp;
                              <span
                                style={{
                                  fontSize: "13px",
                                  paddingRight: "5px",
                                  fontWeight: "bolder",
                                }}
                                class="message-data-time"
                              >
                                {dateconverter(item.timeStamp)}
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  paddingRight: "15px",
                                }}
                                class="message-data-name"
                              >
                                {item.senderUserName}
                              </span>{" "}
                            </div>
                            <div className="imagecontainercat2">
                              <Image
                                style={{ height: "100%", width: "100%" }}
                                className="chatimg float-right"
                                src={item.url}
                              />
                              <CloseOutlined
                                onClick={() => {
                                  if (item.key) {
                                    deleteThisMessage(item);
                                  }
                                }}
                                className="closeSvg3"
                              />
                            </div>
                            {/* <img
                              className="chatimg float-right"
                              src={item.url}
                            /> */}
                            {item.message !== null && item.message !== "" ? (
                              <div class="message my-message mt-3">
                                {item.message}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                      {item.type == "video" ? (
                        <>
                          <div class="comment me">
                            {/* <img className="chataimage" src="https://placekitten.com/300/300?image=10" alt=""/> */}
                            <Avatar
                              className="mt-2 chataimage"
                              style={{ backgroundColor: `#${item.color}` }}
                              alt={item?.senderUserName?.toUpperCase()}
                              src="#"
                            />
                            <div class="message-data align-right">
                              <span class="message-data-time"></span> &nbsp;
                              &nbsp;
                              <span
                                style={{
                                  fontSize: "13px",
                                  paddingRight: "5px",
                                  fontWeight: "bolder",
                                }}
                                class="message-data-time"
                              >
                                {dateconverter(item.timeStamp)}
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  paddingRight: "15px",
                                }}
                                class="message-data-name"
                              >
                                {item.senderUserName}
                              </span>{" "}
                            </div>
                            <div className="imagecontainercat">
                              <video
                                controls
                                height={200}
                                width={270}
                                autoPlay={false}
                                src={item.url}
                              />
                              <CloseOutlined
                                onClick={() => {
                                  if (item.key) {
                                    deleteThisMessage(item);
                                  }
                                }}
                                className="closeSvg4"
                              />
                            </div>
                            {item.message !== null && item.message !== "" ? (
                              <div class="message my-message mt-3">
                                {item.message}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                </>
              ))}
              {imageUploadLoading ? (
                <li className="mt-2">
                  <div class="message-data">
                    <span class="message-data-name">
                      <i class="fa fa-circle online"></i> {currentUser.name}
                    </span>
                    <span class="message-data-time">
                      {dateconverter(Date.now())}
                    </span>
                  </div>
                  <div class="imgcontainer">
                    <img
                      src={URL.createObjectURL(uploadImage)}
                      alt="Avatar"
                      className="imageloading"
                    />
                    <div class="overlayr">
                      <Spinner
                        class="text-center"
                        variant="info"
                        animation="border"
                      />
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
              {vediouploadLoading ? (
                <>
                  <li className="mt-2">
                    <div class="message-data">
                      <span class="message-data-name">
                        <i class="fa fa-circle online"></i> {currentUser.name}
                      </span>
                      <span class="message-data-time">
                        {dateconverter(Date.now())}
                      </span>
                    </div>
                    <div class="imgcontainer">
                      <img
                        src={URL.createObjectURL(uploadCover)}
                        alt="Avatar"
                        className="vedioloading"
                      />
                      <div class="overlayrr">
                        <Spinner
                          class="text-center"
                          variant="info"
                          animation="border"
                        />
                      </div>
                    </div>
                  </li>
                  <div ref={chatRef}></div>
                </>
              ) : (
                ""
              )}

              {fileUploadLoading ? (
                <li className="mt-2">
                  <div class="message-data">
                    <span class="message-data-name">
                      <i class="fa fa-circle online"></i> {currentUser.name}
                    </span>
                    <span class="message-data-time">
                      {dateconverter(Date.now())}
                    </span>
                  </div>
                  <Card
                    size="small"
                    style={{
                      width: 328,
                      height: 217,
                      marginTop: 13,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Spinner
                        class="text-center"
                        variant="info"
                        animation="border"
                      />
                    </div>
                  </Card>
                </li>
              ) : (
                ""
              )}
            </ul>
            {/* <div className="message"ref={messagesEndRef}></div> */}
          </div>

          <div className="bottom-bar">
            <form onSubmit={sendMessages} class="d-flex">
              <Backdrop open={open} />
              <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.speedDial}
                // hidden={hidden}
                icon={<BackupIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={
                      <label className="lableerupload" htmlFor={action.type}>
                        {action.icon}
                      </label>
                    }
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={handleClose}
                  />
                ))}
              </SpeedDial>
              <input
                value={messages}
                onChange={(e) => setMessages(e.target.value)}
                type="text"
                class="form-control"
                placeholder="Enter your message..."
              />
              <Button
                size="large"
                style={{ backgroundColor: "#1e1e2d" }}
                htmlType="submit"
                type="primary"
                icon={<SendOutlined />}
              >
                Send
              </Button>
              {/* <button type="submit" class="msger-send-btn">
                Send
              </button> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chats;
