import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';

const DeleteModal = ({
    HideModal,
    visible,
    id,
    reload,
    url,
    method
}) => {
    const authToken = localStorage.getItem("userToken");
    const [loading, setLoading] = useState(false)

    console.log(url)


    const cancelSub = () => {
        setLoading(true)
        fetch(url, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success("Deleted Successfully")
                    // toast.success(data.metadata.message)
                    setLoading(false)
                    HideModal()
                    reload()
                } else if (!data.metadata.success) {
                    setLoading(false)
                    toast.error(data.metadata.message)
                } else {
                    setLoading(false)
                    toast.error('Unknown error :(')
                }
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }



    return (
        <Modal
            centered
            show={visible}>
            <Modal.Header>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                        <Spinner animation='border' color='green' />
                    </div>
                    :
                    <>
                        <Modal.Body>
                            Are you sure you want to Delete?
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={HideModal}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                                onClick={cancelSub}
                                className='btn btn-primary'>
                                Delete
                            </button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
    )
}

export default DeleteModal