import React, { useState, useEffect } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { NavLink, useHistory } from "react-router-dom";
import { baseUrl } from "../../services/config";
import firebase from "firebase";
// import "./ViewReportCard.css";
import Pagination from "../../_metronic/layout/components/Pagination/pagination";
import Checkbox from "@material-ui/core/Checkbox";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { toast } from "react-hot-toast";
import { notificationUrl } from "../../firebase/config";

function SendNotification() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [CountryName, setCountryName] = useState("");
  const handleClose = () =>{ setShow(false);
  }
  const handleShow = () => setShow(true);
  const handleClose2 = () => {setShow2(false);}

  const resetNotificationFields = () => {
    settitle("");
    setdescription("");
  };


  const handleShow2 = (item) => {
    console.log(item.device_ids, "user===>");
    if (item.device_ids.length == 0) {
      toast.error('User unable to receive notification')
    } else {
      setselectedUser(item);
      setShow2(true);
    }
  };
  const [loading, setloading] = useState(false);
  const [sendToAll, setSendToAll] = useState(false);
  const [disablebutton, setdisablebutton] = useState(false);
  const history = useHistory();
  const authtoken = localStorage.getItem("userToken");
  const [UsersList, setUsersList] = useState([]);
  const [selectedUser, setselectedUser] = useState({});
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  useEffect(() => {
    getAllUser();
    // databaseRef();
  }, []);

  const getAllUser = async () => {
    setloading(true);
    await fetch(`${baseUrl}/admin/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false);
          setUsersList(data.payload.reverse());
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
console.log(title);
console.log(description)
  console.log("selectedUser==>", selectedUser);
  const sendNotificationsToall = async () => {
    setdisablebutton(true);
    UsersList &&
      UsersList.map((user, index) => {
        const pushkey =firebase
        .database()
        .ref("notifications")
        .push().key;
        firebase.database().ref('notifications').child(user.id).child(pushkey).set({
          description: description,
          title: title,
          notificationId:pushkey,
          userName:user.name,
          userId:user.id,
          timeStamp: Date.now(),
        })
        user.device_ids.map(async (item, index) => {
          const notification = {
            message: description,
            title: title,
            // sound: "default", // notification sound
          };

          await fetch(`${notificationUrl}`, {
            headers: {
              "Content-Type": "application/json",
              // Authorization:
              //   "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4",
            },
            method: "POST",
            body: JSON.stringify({
              ...notification,
              deviceToken: item,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("response of notifications===>", data);
              if (index + 1 == UsersList.length) {
                setdisablebutton(false);
                setShow2(false);
                setSendToAll(false);
              }
            })
            .catch((error) => {
              setdisablebutton(false);
              setShow2(false);
              setSendToAll(false);
              console.log("notification error====>", error);
            });
        });
      });
  };

  // const notisender = (title,description) => {
  //   const pushkey = firebase
  //   .database()
  //   .ref("NotificationsAlert")
  //   .push(pushkey)
  //   // firebase.database().ref('NotificationsAlert')
  //   .child(pushkey)
  //   .set({
  //     notificationId:pushkey,
  //     action: 'notification',
  //     // message:description,
  //     title:title,
  //     userName: "Admin",
  //   })
  //   .then( () => {
  //     const notification = {
  //       title:title,
  //       body:description,
  //     };
 
  //     Object.values(UsersList).map((item,i)=>{

  //       console.log(item.device_ids);
        
  //      fetch('https://fcm.googleapis.com/fcm/send', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4"
  //       },
  //       method: 'POST',
  //       body: JSON.stringify({
  //         notification: notification,
  //         // to: item.device_ids? item.device_ids[0]:"",
  //       }),
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log('response', data);
  //       })
  //       .catch(err=>{
  //         console.log(err);
  //       })
  //   })
    
  //  })

  // };
  // console.log(UsersList)
  // console.log(groupName?.groupsUsers)


  const sendNotifications = async () => {
    const pushkey =firebase
    .database()
    .ref("notifications")
    .push().key;
    firebase.database().ref('notifications')
    .child(selectedUser.id)
    .child(pushkey).set({
      description: description,
      title: title,
      notificationId:pushkey,
      userName:selectedUser.name,
      userId:selectedUser.id,
      timeStamp: Date.now(),
    })

    

    selectedUser.device_ids.map(async (item, index) => {
      const notification = {
        message: description,
        title: title,
        // sound: "default", // notification sound
      };

      await fetch(`${notificationUrl}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization:
          //   "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4",
        },
        method: "POST",
        body: JSON.stringify({
          ...notification,
          deviceToken: item,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response of notifications===>", data);
          setShow2(false);
          if (index + 1 == selectedUser?.device_ids + 1) {
            setShow2(false);
          }
        })
        .catch((error) => {
          console.log("notification error====>", error);
        });
    });
  };
  const sendToall = () => {
    setSendToAll(true);
    setShow2(true);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <Button variant="primary" onClick={handleShow}>
          <span className="svg-icon menu-icon">
            <NotificationsActiveIcon />
          </span>
          Send Notification
        </Button> */}
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control required type="text" placeholder="Title" />
          <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control placeholder="Descriptions" as="textarea" rows={3} />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>

      <ListGroup className="mt-5">
        <div className="row">
          <div className="col-md-3">
            <button onClick={sendToall} className="btn btn-primary">
              Send To All
            </button>
          </div>
        </div>
        <Table responsive>
          <thead>
            <tr>
              {/* <th>
                <Checkbox />
              </th> */}
              <th>ID</th>
              <th>Name</th>
              <th>View</th>
              {/* <th>Edit</th>
              <th>Delete</th> */}
            </tr>
          </thead>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <span className="spinner-border text-danger text-center"></span>
            </div>
          ) : (
            UsersList.map((item, index) => (
              <tbody key={item.id}>
                <tr className="colorChange">
                  {/* <td>
                    <Checkbox />
                  </td> */}
                  <td style={{ verticalAlign: "middle" }}>{item.id}</td>
                  <td style={{ verticalAlign: "middle" }}>{item.name}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <span
                      onClick={() => {
                        setSendToAll(false);
                        handleShow2(item);
                      }}
                      className="ml-1 svg-icon svg-icon-md svg-icon-primary"
                      style={{ cursor: "pointer" }}
                    >
                      <NotificationsActiveIcon />
                    </span>
                  </td>
                </tr>
              </tbody>
            ))
          )}
        </Table>
      </ListGroup>

      {/* start  single notification model */}
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show2}
        onHide={handleClose2}
      >
        <Modal.Header>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            required
            onChange={(e) => {
              settitle(e.target.value);
            }}
            type="text"
            placeholder="Title"
          />
          <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              placeholder="Descriptions"
              onChange={(e) => {
                setdescription(e.target.value);
              }}
              as="textarea"
              rows={3}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Close
          </Button>
          {sendToAll ? (
            <Button
              disabled={disablebutton}
              variant="primary"
              onClick={() => {sendNotificationsToall();
                resetNotificationFields();}
              }
            >
              Send
            </Button>
          ) : (
            <Button variant="primary" onClick={() => {sendNotifications();
            resetNotificationFields()}}>
              Send
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* end  single notification model */}
    </>
  );
}

export default SendNotification;
