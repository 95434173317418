import React, { useEffect } from "react";
// import "./notifations.css";
import { Avatar } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import firebase from "firebase";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import UseFirebasePagination from "../../Components/FirebasePagination";
import UsePagination from "../../Components/UsePagination";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import usePagination2 from "../../Components/usePagination2";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const GroupNotifications = ({ item }) => {
  const classes = useStyles();
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [deleteModel, setdeleteModel] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(notificationList.length / PER_PAGE);
  const _DATA = usePagination2(notificationList, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    // getNotifications(currentPage);
    getAllNotifications();
  }, []);

  // useEffect(() => {
  //   // Fetch total notifications count
  //   firebase
  //     .database()
  //     .ref('notificationsAlert')
  //     .once('value')
  //     .then((snapshot) => {
  //       const totalNotifications = snapshot.numChildren();
  //       const totalPages = Math.ceil(totalNotifications / itemsPerPage);

  //       const paginationLinks = Array.from({ length: totalPages }, (_, i) => ({
  //         label: `${i + 1}`,
  //         page: i + 1,
  //       }));

  //       console.log("links",paginationLinks)
  //       // Render pagination with the total pages
  //       // renderPagination(paginationLinks);
  //       setlinks(paginationLinks)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching notifications count: ', error);
  //     });
  // }, []);

  const getAllNotifications = () => {
    setloading(true);
    firebase
      .database()
      .ref("notifications")
      .once("value")
      .then((snapshot) => {
        const allNotifications = [];
        snapshot.forEach((userSnapshot) => {
          userSnapshot.forEach((notificationSnapshot) => {
            allNotifications.push({
              ...notificationSnapshot.val(),
            });
          });
        });

        allNotifications.sort((a, b) => b.timeStamp - a.timeStamp);
        allNotifications.map((item, i) => {
          item.sr = i + 1;
        });
        setNotificationList(allNotifications);
        console.log(allNotifications, "ya hy new notifications");
        setloading(false);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        setloading(false);
      });
  };

  // const getAllNotifications=()=>{
  //   setloading(true)
  //   firebase
  //   .database()
  //   .ref('notificationsAlert')
  //   .on('value', (snapshot) => {
  //     let arr = [];
  //     let transform = [];
  //     if (snapshot.exists()) {
  //       snapshot.forEach((item,i) => {
  //         let value = item.val();
  //         value.id = item.key;
  //         arr.push(value);
  //       });
  //       arr.map((item,i)=>{
  //        item.sr=i+1
  //       })
  //       setNotificationList(arr);
  //       setloading(false)
  //     } else {
  //     }
  //   });
  // }
  // const getNotifications = (page) => {
  //   const itemsPerPage = 10;
  //   const startKey = (page - 1) * itemsPerPage;

  //   firebase
  //     .database()
  //     .ref('notificationsAlert')
  //     .orderByKey()
  //     .startAt(startKey.toString())
  //     .limitToFirst(itemsPerPage)
  //     .once('value')
  //     .then((snapshot) => {
  //       const arr = [];
  //       snapshot.forEach((item) => {
  //         const value = item.val();
  //         value.id = item.key;
  //         arr.push(value);
  //       });
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching notifications:', error);
  //     });
  // };

  const getTime = (timestamp) => {
    const now = new Date();
    const elapsedMilliseconds = now.getTime() - timestamp;

    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    let message = "";

    if (elapsedDays > 0) {
      message = elapsedDays + "d" + " " + "ago";
    } else if (elapsedHours > 0) {
      message = elapsedHours + "h" + " " + "ago";
    } else if (elapsedMinutes > 0) {
      message = elapsedMinutes + "m" + " " + "ago";
    } else {
      message = "just now";
    }

    return message;
  };

  const deleteThisNotifaction = () => {
    firebase
      .database()
      .ref("notifications")
      .child(selectedId.userId)
      .child(selectedId.notificationId)
      .remove()
      .then(() => {
        getAllNotifications()
        toast.success('Deleted Successfully')
        setdeleteModel(false);
      }).catch(err=>console.log(err))
  };

  // const totalNotifications = notificationList.length;
  // const totalPages = Math.ceil(totalNotifications / itemsPerPage);
  // const paginationLinks = Array.from({ length: totalPages }, (_, i) => ({
  //   label: `${i + 1}`,
  //   page: i + 1,
  // }));

  console.log(_DATA.currentData(), "ya hy");

  return (
    <>
      <Card>
        <>
          <CardHeader title="Notifications History"></CardHeader>

          <CardBody>
            <Table responsive>
              <thead style={{ marginTop: "20px" }}>
                <tr id="tbl">
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Title
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Username
                  </th>

                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                      width: "70vw",
                      position: "absolute",
                    }}
                  >
                    <span className="spinner-border text-danger text-center"></span>
                  </div>
                ) : (
                  _DATA.currentData().map((item, index) => (
                    <tr
                      className="colorChange"
                      id="tbl"
                      style={{ textAlign: "center" }}
                      key={item.id}
                    >
                      <td>{item.sr}</td>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      <td>{item.userName}</td>
                      <td>
                        <span
                          title="delete"
                          onClick={() => {
                            setSelectedId(item);
                            setdeleteModel(true);
                          }}
                          style={{ cursor: "pointer" }}
                          className="svg-icon svg-icon-md svg-icon-danger ml-4"
                        >
                          <SVG
                            title="delete"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {/* {renderPagination} */}

            {!loading ? (
              <div className={classes.root}>
                <Pagination
                  color="primary"
                  page={page}
                  count={count}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </div>
            ) : (
              ""
            )}

            {/* <UseFirebasePagination links={links} onPaginate={handlePagination} /> */}
          </CardBody>
        </>
      </Card>
      <Modal centered show={deleteModel}>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete?</Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setdeleteModel(false)}
            className="btn btn-danger"
          >
            Close
          </button>

          <button onClick={deleteThisNotifaction} className="btn btn-primary">
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupNotifications;
