import React from 'react';
import './ChatBar.css'; // Import the CSS file for styling

const ChatBar = () => {


    const messages = [
        {
            name: 'John',
            text: 'Hello! dfgfdg fg fdgdf dfg fdgdf dfg df',
            isUser: true
        },
        {
            name: 'Jane',
            text: 'Hi there!',
            isUser: false
        },
        {
            name: 'John',
            text: 'Hello!',
            isUser: true
        },
        {
            name: 'Jane',
            text: 'Hi there!',
            isUser: false
        },
        {
            name: 'John',
            text: 'Hello!',
            isUser: true
        },
        {
            name: 'Jane',
            text: 'Hi there!',
            isUser: false
        },
        {
            name: 'John',
            text: 'Hello!',
            isUser: true
        },
        {
            name: 'Jane',
            text: 'Hi there!',
            isUser: false
        },
        // Add more messages as needed
    ];


    return (
        <div>
            <div className="chat-window">
                <div className="chat-messages">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`chat-bubble ${message.isUser ? 'user' : 'other'}`}
                        >
                            {message.isUser ? (
                                <div className="user-avatar"></div>
                            ) : (
                                <div className="other-avatar"></div>
                            )}
                            <div
                                style={{ backgroundColor: message.isUser ? '#242526' : 'gray' }}
                                className="message-content">
                                <p className="name">{message.name}</p>
                                <p className="message">{message.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="input-bar">
                    <input type="text" placeholder="Type your message..." />
                    <button>Send</button>
                </div>
            </div>
        </div>
    );
};

export default ChatBar;
