import React, { useState, useEffect } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { NavLink, useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import "./ViewReportCard.css";
import Pagination from "../../../_metronic/layout/components/Pagination/pagination";
import DeleteModal from "../../Components/DeleteModal";
import firebase from "firebase";
import { database } from "../../../firebase/config";
import { Image } from "antd";

function AllUsers() {
  const [show, setShow] = useState(false);
  const [CountryName, setCountryName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const authtoken = localStorage.getItem("userToken");
  const [UsersList, setUsersList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getAllUser();
  }, []);

  // let a = "ahmad"
  // let  b = "this is my name"+a
  // let c = `this is my name ${a}`

  const getAllUser = async () => {
    setloading(true);
    await fetch(`${baseUrl}/admin/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false);
          setUsersList(data.payload.reverse());
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const deleteItem = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };
  // console.log(item)
 console.log(UsersList)
  // const edititem = (item) => {
  //   handleShow()
  //   setCountryName(item.name);

  // };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Add Users
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            required
            value={CountryName}
            type="text"
            placeholder="Enter Users Name"
            onChange={(e) => {
              setCountryName(e.target.value);
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>
      <ListGroup className="mt-5">
        <Table responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Image</th>
              <th>Name</th>
              <th>View</th>
              <th>Delete</th>
              {/* <th>Edit</th>
              <th>Delete</th> */}
            </tr>
          </thead>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <span className="spinner-border text-danger text-center"></span>
            </div>
          ) : (
            UsersList.map((item, index) => (
              <tbody key={item.id}>
                <tr className="colorChange">
                  <td>{item.id}</td>
                  <td>
                    {item.image?
                     <Image style={{borderRadius:"100%"}} height={35} width={35} className="chatimg" src={item.image.original_url} />
                    :"N/A"} 
                  </td>
                  <td>{item.name}</td>
                  <td>
                    <span
                    title="view"
                      onClick={() => {
                        history.push(`/view-user/${item.id}`, {
                          userData: item,
                        });
                      }}
                      className="ml-1 svg-icon svg-icon-md svg-icon-primary"
                      style={{ cursor: "pointer" }}
                    >
                      <SVG
                      title="view"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Devices/Display2.svg"
                        )}
                      />
                    </span>
                  </td>
                  {/* <td>
                  <span  className="ml-1 svg-icon svg-icon-md svg-icon-primary" 
                  onClick={()=> edititem(item)}>
                     <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                  </span>
                </td> */}
                  <td>
                    <span
                    title="delete"
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger ml-4"
                      onClick={() => {
                        deleteItem(item.id);
                      }}
                    >
                      <SVG
                       title="delete"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            ))
          )}
        </Table>
      </ListGroup>

      <DeleteModal
        HideModal={() => setDeleteModal(false)}
        method="DELETE"
        reload={() => getAllUser()}
        visible={deleteModal}
        url={`${baseUrl}/admin/user/${selectedId}`}
      />
    </>
  );
}

export default AllUsers;
