import React from 'react';

function UsePagination({ links, onPaginate }) {
    return (
        <nav>
            <ul className="pagination" style={{ flexWrap: "wrap", listStyle: "none", display: "flex", justifyContent: "center" }}>
                {links.map((link, index) => (
                    <li key={index} className={`page-item ${link.url === null || link.active ? 'disabled' : 'active'} m-2`}>
                        <a
                            onClick={() => onPaginate(link)}
                            className="page-link"
                            tabIndex="1"
                            style={{ textDecoration: "none",cursor: "pointer" }}
                        >
                            {link.label === 'Next &raquo;' ? <span>&raquo;</span> : link.label === '&laquo; Previous' ? <span>&laquo;</span> : link.label}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default UsePagination;
