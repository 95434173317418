import React, { useState, useEffect } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import ViewReportCard from "../TotalCountries/ViewReportCard";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Table from "react-bootstrap/Table";
import { baseUrl } from "../../../services/config";
import SVG from "react-inlinesvg";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import { useParams } from "react-router-dom";
import usePagination2 from "../../Components/usePagination2";
import { notificationUrl } from "../../../firebase/config";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function Viewuser(props) {
  const userData = props.location.state.userData;
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [url, seturl] = useState("");
  const [showcountryName, setshowcountryName] = useState("");
  const [addLoading, setaddLoading] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);
  const [selecteditem, setselecteditem] = useState({});
  const [viewmodal, setviewmodal] = useState(false);
  const [editloading, seteditloading] = useState(false);
  const [showeditmodal, setshoweditmodal] = useState(false);
  const [edittitel, setedittitel] = useState("");
  const [editdescription, seteditdescription] = useState("");

  const [editurl, setediturl] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseModel = () => setDeleteModel(false);

  const handleDeleteModel = () => {
    deleteitem(selecteditem);
    handleCloseModel();
  };

  const authtoken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);

  const [reportList, setreportList] = useState([]);
  const userId = props.match.params.userid;
  console.log(userId);

  const [postPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  let currentPosts = [];
  currentPosts = reportList.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pagenumber) => setCurrentPage(pagenumber);
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(reportList.length / PER_PAGE);
  const _DATA = usePagination2(reportList, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
 
  useEffect(() => {
    getUserId();
  }, []);

  const getUserId = async () => {
    setloading(true);
    await fetch(`${baseUrl}/admin/user-reports/${userData.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          setloading(false);
          let rsultant=data.payload.reverse()
          rsultant.forEach((item,i)=>{
              item['sr']=i+1
          })
          setreportList(rsultant);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const handleadd = () => {
    if (title == "" || description == "") {
      alert("Enter AL Name");
    } else {
      handleAddReport();
    }
  };

  const handleAddReport = async () => {
    setaddLoading(true);
    const newObject = {
      user_id: userData.id,
      title: title,
      description: description,
      url: url,
    };

    await fetch(`${baseUrl}/admin/user-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(newObject),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          getUserId();
          setaddLoading(false);
          notification();
          handleClose();

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteitem = async (item) => {
    setdeleteloading(true);
    await fetch(`${baseUrl}/admin/user-report/${item.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setdeleteloading(false);
        handleCloseModel();
        if (data.metadata) {
          if (data.metadata.success) {
            getUserId();

            handleCloseModel();
          }
        }
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
        handleCloseModel();
        setdeleteloading(false);
      });
  };

  const handleClosemodal = () => {
    console.log(selecteditem);
    setviewmodal(false);
  };
  const handelEditClose = () => {
    setshoweditmodal(false);
    setedittitel("");
    setediturl("");
    seteditdescription("");
  };

  const handelEditopen = (value) => {
    setshoweditmodal(true);
    setedittitel(value.title);
    setediturl(value.url);
    seteditdescription(value.description);
  };

  const handleSave = async () => {
    seteditloading(true);
    const data = {
      user_id: userData.id,
      title: edittitel,
      description: editdescription,
      url: editurl,
    };

    await fetch(`${baseUrl}/admin/user-report/${selecteditem.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("this is eidit resp ", data);
        handleCloseModel();
        if (data.metadata) {
          if (data.metadata.success) {
            seteditloading(false);
            getUserId();
            handelEditClose();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        handleCloseModel();
        seteditloading(false);
      });
  };

  //  notification
  const notification = async () => {


    //////['token1','token2']

    console.log("user id ====>", userData.device_ids)




    userData.device_ids.map(async (item, index) => {
      const notification = {
        message: description,
        title: title,
        // sound: 'default' // notification sound 
      };


      await fetch(notificationUrl, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4"
        },
        method: "POST",
        body: JSON.stringify({
          ...notification,
          deviceToken: item,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response of notifications===>", data);
        })
        .catch((error) => {
          console.log("notification error====>", error);
        });

    });

  };

  return (
    <>
      <div
        className="mb-5"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <h2>Report View</h2>
        </div>
        <div>
          <Button
            style={{
              display: "flex-end",
              background: "rgb(9, 17, 37)",
              border: "none",
            }}
            onClick={handleShow}
          >
            Add Report
          </Button>
        </div>
      </div>
      <div>
        {/* {reportList.map((item) => {
          return (
            <ViewReportCard
              key={item.id}
              onClickdelete={() => deleteitem(item)}
              item={item}
              selecteditem={setselecteditem}
              show={setviewmodal}
              showdeletemodal={setDeleteModel}
              editmodal={handelEditopen}
            />
          );
        })} */}
        <ListGroup className="mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th>SR.NO</th>
                <th>TITLE</th>
                <th>DESCRIPTION</th>
                <th>URL</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                  width: "70vw",
                  position: "absolute",
                }}
              >
                <span className="spinner-border text-danger text-center"></span>
              </div>
            ) : (
              <>
                {_DATA.currentData().map((item, index) => (
                  <tbody>
                    <tr className="selectuser">
                      <td>{item.sr}</td>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      <td>{item.url}</td>
                      <td>
                        <span
                        title="view"
                          onClick={() => {
                            setselecteditem(item);
                            setviewmodal(true);
                          }}
                          className="svg-icon svg-icon-md svg-icon-primary "
                          style={{ cursor: "pointer" }}
                        >
                          <SVG
                           title="view"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Devices/Display2.svg"
                            )}
                          />
                        </span>
                      </td>
                      <td>
                        <span
                          title="edit"
                          onClick={() => {
                            setselecteditem(item);
                            handelEditopen(item);
                            setshoweditmodal(true);
                          }}
                          className="ml-1 svg-icon svg-icon-md svg-icon-primary "
                          style={{ cursor: "pointer" }}
                        >
                          <SVG
                           title="edit"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </td>
                      <td>
                        <span
                        title="delete"
                          className="svg-icon svg-icon-md svg-icon-danger "
                          style={{
                            color: "red",
                            size: "80",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setselecteditem(item);
                            setDeleteModel(true);
                          }}
                        >
                          <SVG
                           title="delete"
                            style={{ cursor: "pointer" }}
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
                {/* <div style={{ position: "absolute", right: "90px" }}>
                  <Pagination
                    postsPerPage={postPerPage}
                    paginate={paginate}
                    totalPosts={reportList.length}
                  />
                </div> */}
              </>
            )}
          </Table>
          {
              !loading ?
              <div className={classes.root}>
              <Pagination color="primary" page={page} count={count} variant="outlined" shape="rounded"
                  onChange={handleChange}
              
              />
            </div>:''
            }
        </ListGroup>
      </div>

      <Modal show={showeditmodal} onHide={handelEditClose}>
        <Modal.Header>
          <Modal.Title>Edit Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editloading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30vw",
                position: "absolute",
                top: "10px",
                bottom: "15px",
              }}
            >
              <span className="spinner-border text-success "></span>
            </div>
          ) : (
            <>
              <h3>Title</h3>
              <Form.Control
                defaultValue={edittitel}
                value={edittitel}
                onChange={(e) => {
                  setedittitel(e.target.value);
                }}
              />

              <hr />
              <h3>Description</h3>
              <Form.Control
                defaultValue={editdescription}
                value={editdescription}
                onChange={(e) => {
                  seteditdescription(e.target.value);
                }}
              />
              <hr />
              <h3>Url</h3>

              <Form.Control
                defaultValue={editurl}
                value={editurl}
                onChange={(e) => {
                  setediturl(e.target.value);
                }}
              />
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handelEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size={'lg'}
        show={viewmodal} onHide={handleClosemodal}>
        <Modal.Header>
          <Modal.Title>Show Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Title</h3>
          <h6>{selecteditem.title}</h6>
          <hr />
          <h3>Description</h3>
          {selecteditem.description}
          <hr />
          <h3>Url</h3>
          <h6>{selecteditem.url}</h6>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClosemodal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30vw",
                position: "absolute",
                top: "10px",
                bottom: "15px",
              }}
            >
              <span className="spinner-border text-success text-center"></span>
            </div>
          ) : (
            <>
              <Form.Control
                required
                type="text"
                placeholder="Title"
                onChange={(e) => {
                  settitle(e.target.value);
                }}
              />

              <Form.Control
                onChange={(e) => {
                  setdescription(e.target.value);
                }}
                className="mt-2"
                type="text"
                placeholder="Description"
              />

              <Form.Control
                onChange={(e) => {
                  seturl(e.target.value);
                }}
                className="mt-2"
                type="text"
                placeholder="URL"
              />
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleadd}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={deleteModel} onHide={handleCloseModel}>
        <Modal.Header>
          <Modal.Title>Delete Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you Want to Delete</h3>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModel}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteModel}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={deleteModel} onHide={handleCloseModel}>
        <Modal.Header>
          <Modal.Title>Delete Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteloading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30vw",
                position: "absolute",
                top: "10px",
                bottom: "15px",
              }}
            >
              <span className="spinner-border text-success text-center"></span>
            </div>
          ) : (
            <>
              <h3>Are you sure you Want to Delete?</h3>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModel}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteModel}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Viewuser;
