import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../_metronic/_partials/controls";
import toast from 'react-hot-toast'
import { baseUrl } from "../../../services/config";
import {useParams} from 'react-router-dom'
// Validation schema


export function AddSubKpis({ saveCustomer, customer, actionsLoading, onHide,reload,editItem }) {
  const [loading,setLoading]=useState(false)
  const params=useParams()

  const authtoken = localStorage.getItem("userToken");

    const CustomerEditSchema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });
    
    const editinitialValues = {
        name: editItem.name
    }
    const addinitialValues = {
        name:''
    }
    
    
    const handleSubmit =(values)=>{
        setLoading(true)
    const data={
        name:values.name,
    }
    const editUrl=`admin/sub-kpis/${editItem.id}?_method=PUT`
    const addUrl=`admin/kpis/${params.id}/sub-kpis`

    fetch(`${baseUrl}/${editItem?editUrl:addUrl} `, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body:JSON.stringify(data) ,
      }).then((resp) => {
        setLoading(false)
        resp.json().then((data) => {
        if(data.metadata){
            if(data.metadata.success){
                reload()
               onHide()

                 toast.success(data.metadata.message)
               }else if(!data.metadata.success){
                Object.values(data.metadata.message).map(msg => {
                  toast.error(msg)
               })
               }
        
        }
         
        });
      }).catch(err => {
        setLoading(false)
        console.log(err)
        toast.error('Opps something wents wronge')
        onHide()
      })
    }   


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem !='' ?editinitialValues:addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
            handleSubmit(values);
          }}
      >
        {({values}) => (
          <>
              <Form  className="form form-label-right">
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
                <div className="form-group row">    
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <label>Enter Sub kpis</label>
                    <Field
                    // style={{ height: "8rem" }}
                    value={values.name}
                    name="name"
                    component={Input}
                    placeholder="Enter name"
                  />
                  </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                disabled={loading}
                type="submit"
                // onClick={() =>  handleSubmit(values)}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            </Form>

          </>
        )}
      </Formik>
    </>
  );
}
