import { baseUrl } from "../../services/config"
const authtoken = localStorage.getItem("userToken");

export const uploadFiles = async (file) => {
    const fdata = new FormData();
    fdata.append('file', file);
  
    try {
      const response = await fetch(`${baseUrl}/chats`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
        body: fdata,
      });
  
      const data = await response.json();
      console.log(data);
      return data;
    } catch (err) {
      console.error("Error in file upload:", err);
    }
  };

  export const deleteFile = async (id) => {
 
  
    try {
      const response = await fetch(`${baseUrl}/chats/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      });
      const data = await response.json();
      console.log(data);
    } catch (err) {
      console.error("Error in file upload:", err);
    }
  };