import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import { Modal, Spinner, Table } from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {AddSubKpis} from './AddSubKpis'
import DeleteModal from "../../Components/DeleteModal";
import {useParams} from 'react-router-dom'
import { Empty } from "antd";
export function SubKpis() {

  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [subKpis, setSubKpis] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [editItem, setEditItem] = useState({});
  const authtoken = localStorage.getItem("userToken");
  const params=useParams()


  

  useEffect(() => {
    getSubKpis();
    console.log("mounting....");
  }, []);

  const getSubKpis = () => {
    setloading(true);
    fetch(`${baseUrl}/admin/kpis/${params.id}/sub-kpis`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        console.log("brands List", data.payload);
        setSubKpis(data.payload.reverse());
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };


  const openEdit = (item) => {
    setEditItem(item);
    seteditModal(true);
    setaddModal(true);
  };

  const deleteItem = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };

  

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <>
          <CardHeader title={loading ?'': subKpis.length?`${subKpis[0]?.kpi?.name} Sub Kpis list`:'Sub Kpis list'}>
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary mx-2"
                onClick={() => {
                  seteditModal(false);
                  setaddModal(true);
                }}
              >
                New Sub Kpis
              </button>
            </CardHeaderToolbar>
          </CardHeader>
 
          <CardBody>
          <Table responsive>
              <thead>
                <tr id="tbl" >
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Edit
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {
               
                  subKpis?.map((sta, i) => (
                    
                    <tr key={i} id="tbl" className='colorChange'>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta.name}
                      </td>
                 
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                    <span
                    title="edit"
                      style={{ cursor: "pointer" }}
                      className="ml-1 svg-icon svg-icon-md svg-icon-primary" 
                      onClick={() => {
                        openEdit(sta);
                      }}
                    >
                      <SVG
                       title="edit"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      />
                    </span>
                  </td>
                      <td
                       style={{
                        textAlign: "center",
                      }}
                      >
                    <span
                    title="delete"
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger ml-4"
                      onClick={() => {
                        deleteItem(sta.id);
                      }}
                    >
                      <SVG
                        title="delete"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                    </tr>
                  ))
                  
                }
              </tbody>
              
              
            </Table>
          </CardBody>
        </>
      </Card>
      <Modal centered show={addModal}>
        <Modal.Header>
          <h4>{editModal ? "Edit kpis" : "Add New kpis"}</h4>
        </Modal.Header>
        <AddSubKpis
          editItem={editModal ? editItem : ""}
          reload={getSubKpis}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>
     
      <DeleteModal
        HideModal={() => setDeleteModal(false)}
        method="DELETE"
        reload={() => getSubKpis()}
        visible={deleteModal}
        url={`${baseUrl}/admin/sub-kpis/${selectedId}`}
      />
    </>
  );
}
