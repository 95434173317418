import firebase from 'firebase';
import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';

const DeleteGroup = ({
    HideModal,
    visible,
    id,
}) => {

    const [loading, setLoading] = useState(false)


    const cancelSub = () => {
        setLoading(true)
        firebase.database().ref('groups')
            .child(id)
            .remove()
            .then(() => {
                toast.success(`Deleted Successfully`)
                HideModal()
                setLoading(false)
            }).catch(err => {
                toast.error(err.message)
                setLoading(false)

            })
    }



    return (
        <Modal
            centered
            show={visible}>
            <Modal.Header>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                        <Spinner animation='border' color='green' />
                    </div>
                    :
                    <>
                        <Modal.Body>
                            Are you sure you want to Delete Group?
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={HideModal}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                                onClick={cancelSub}
                                className='btn btn-primary'>
                                Delete
                            </button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
    )
}

export default DeleteGroup
