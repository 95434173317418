/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import Routes from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { baseUrl } from "../services/config";
import firebase from "firebase";
import { firebaseConfig, getTokenfirebase, onMessageListener } from "../firebase/config";
import toast, { Toaster } from 'react-hot-toast';
import ringtone from './ringtone.mp3'


export default function App({ store, persistor, basename }) {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  const [payload, setpayload] = useState()
  getTokenfirebase(setTokenFound);
  console.log(isTokenFound);
  const authtoken = localStorage.getItem("userToken");

  const notify = () => toast(<ToastDisplay />, { duration: 9000, });

  function ToastDisplay() {


    // const gotoChats = () => {

    // }


    return (
      <div onClick={() => window.location.href = payload?.notification.click_action}>
        <p><b>{notification.title}</b></p>
        <p>{notification.body}</p>
      </div>
    );
  };

  onMessageListener().then(payload => {
    const audio = new Audio(ringtone)
    audio.play()
    setTimeout(() => {
      audio.pause()
    }, 5000);
    console.log("payload", payload)
    setpayload(payload)
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
  }).catch(err => console.log('failed: ', err));
  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  useEffect(() => {
    getAllUser();
  }, []);

  console.log(notification)
  const getAllUser = async () => {
    await fetch(`${baseUrl}/admin/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {

          if (data.payload) {
            Promise.all(
              data?.payload.map(user => {
                return firebase.database().ref('users')
                  .child(user.id)
                  .set({
                    ...user,
                    userId: user.id,
                    lastUpdate: Date.now()
                  }).then(res => {

                  })
              })
            ).then(() => {
              console.log('updated')
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              console.log('done')
            })

          }

        }

      })
      .catch((err) => {
        console.log(err);

      });
  };







  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  <Routes />
                  <Toaster />
                </AuthInit>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
