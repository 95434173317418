import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, DatePickerField } from "../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import Select from "react-select";
import { baseUrl } from "../../../services/config";
// Validation schema

export function AddDailyReports({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  reload,
  editItem,
  users,
  kpis,
  stations,
}) {
  const [loading, setLoading] = useState(false);
  const [subKpis, setSubKpis] = useState(false);

  const authtoken = localStorage.getItem("userToken");
useEffect(() => {
if(editItem){
  getSubKpis(editItem?.sub_kpi?.kpi?.id)
}
}, [editItem])

  const CustomerEditSchema = Yup.object().shape({
    total: Yup.number().required("Total Points is required"),
    achieved: Yup.number().required("Achieved Points is required"),
    date: Yup.date().required("Date is required"),
    user: Yup.string().required("Group is required"),
    kpis: Yup.string().required("Kpis is required"),
    subKpis: Yup.string().required("Sub Kpis is required"),
    // station: Yup.string().required("Station is required"),
    shift: Yup.string().required("Shift is required"),
  });

  const addinitialValues = {
    total: "",
    achieved: "",
    date: '',
    user: "",
    kpis:"",
    subKpis:"",
    // station:"",
    shift:"",
  };
  const editinitialValues = {
    total:editItem?.total_points,
    achieved:editItem?.achieved_points,
    date:editItem?.date,
    user: editItem?.user?.id,
    kpis:editItem?.sub_kpi?.kpi?.id,
    subKpis:editItem?.sub_kpi?.id,
    // station:editItem?.station?.id,
    shift:editItem?.shift,

  };

  const handleSubmit = (values) => {
    console.log(values)
    let d2 = new Date(values.date)
    var curr_moth2 = ("0" + (d2.getMonth() + 1)).slice(-2)
    var curr_date2 = ("0" + (d2.getDate())).slice(-2)
    let ddd2 = `${new Date(d2).getFullYear()}-${curr_moth2}-${curr_date2}`

    setLoading(true);
    const data = {
      achieved_points: values.achieved,
      sub_kpi_id: values.subKpis,
      group_id: values.user,
      // station_id: values.station,
      total_points: values.total,
      shift: values.shift,
      date:ddd2,
    };
    const editUrl = `admin/daily-reports/${editItem.id}?_method=PUT`;
    const addUrl = `admin/daily-reports`;

    fetch(`${baseUrl}/${editItem ? editUrl : addUrl} `, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        setLoading(false);
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              reload();
              onHide();

              toast.success(data.metadata.message);
            } else if (!data.metadata.success) {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            }
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Opps something wents wronge");
        onHide();
      });
  };

  const getSubKpis = (id) => {
    fetch(`${baseUrl}/admin/kpis/${id}/sub-kpis`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.payload) {
          let arr = [];
          data.payload.forEach((item) => {
            arr.push({ label: item.name, value: item.id });
          });
          setSubKpis(arr.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#187DE4d" : "white",
        color: "#000000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };
  const colourStylesInvalid = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "red",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#187DE4d" : "white",
        color: "#000000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, errors, handleBlur, touched, setFieldValue }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <label>Select Groups</label>
                    <Select
                    defaultValue={
                      editItem &&
                        {
                          label: editItem?.user?.name,
                          value: editItem?.user?.id,
                        }
                        
                    }
                      styles={
                        errors.user && touched.user
                          ? colourStylesInvalid
                          : colourStyles
                      }
                      name="user"
                      className=""
                      placeholder="groups"
                      options={users}
                      onChange={(option) => setFieldValue("user", option.value)}
                      onBlur={handleBlur}
                      values={values.user}
                    />

                    {errors.user && touched.user && (
                      <span className="invalid-datepicker-feedback text-danger">
                        {errors.user}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <label>Select Kpis</label>
                    <Select
                      defaultValue={
                        editItem &&
                          {
                            label: editItem?.sub_kpi?.kpi?.name,
                            value: editItem?.sub_kpi?.kpi?.id,
                          }
                       
                      }
                     styles={
                      errors.kpis && touched.kpis
                        ? colourStylesInvalid
                        : colourStyles
                    }
                      onChange={(e) => {
                        getSubKpis(e.value);
                        setFieldValue("kpis", e.value)
                        // setFieldValue("subKpis",'')
                      }}
                      name="kpis"
                      className=""
                      placeholder="Kpis"
                      options={kpis}
                      onBlur={handleBlur}
                      values={values.kpis}
                    />
                      {errors.kpis && touched.kpis && (
                      <span className="invalid-datepicker-feedback text-danger">
                        {errors.kpis}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <label>Select Sub Kpis</label>
                    <Select
                     defaultValue={
                      editItem &&
                        {
                          label: editItem?.sub_kpi?.name,
                          value: editItem?.sub_kpi?.id,
                        }
                    }
                    styles={
                      errors.subKpis && touched.subKpis
                        ? colourStylesInvalid
                        : colourStyles
                    }
                     onChange={(option) => setFieldValue("subKpis", option.value)}
                      className=""
                      placeholder="Sub Kpis"
                      options={subKpis || []}
                      name="subKpis"
                      onBlur={handleBlur}
                      values={values.subKpis}
                    />
                       {errors.subKpis && touched.subKpis && (
                      <span className="invalid-datepicker-feedback text-danger">
                        {errors.subKpis}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <label>Select Station</label>
                    <Select
                     defaultValue={
                      editItem &&
                       {
                          label: editItem?.station?.name,
                          value: editItem?.station?.id,
                        }
                      
                    }
                        styles={
                          errors.station && touched.station
                            ? colourStylesInvalid
                            : colourStyles
                        }
                      onChange={(option) => setFieldValue("station", option.value)}
                      className=""
                      placeholder="Station"
                      options={stations}
                      name="station"
                      onBlur={handleBlur}
                      values={values.station}
                    />
                      {errors.station && touched.station && (
                      <span className="invalid-datepicker-feedback text-danger">
                        {errors.station}
                      </span>
                    )}
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <label>Select Shift</label>
                    <Select
                     defaultValue={
                      editItem
                        && {
                          label: editItem?.shift,
                          value: editItem?.shift,
                        }
                      
                    }
                      styles={
                      errors.shift && touched.shift
                        ? colourStylesInvalid
                        : colourStyles
                    }
                      onChange={(option) => setFieldValue("shift", option.value)}
                      className=""
                      placeholder="Shift"
                      options={[
                        { label: "Morning", value: "Morning" },
                        { label: "Evening", value: "Evening" },
                      ]}
                      name="shift"
                      onBlur={handleBlur}
                      values={values.shift}
                    />
                      {errors.shift && touched.shift && (
                      <span className="invalid-datepicker-feedback text-danger">
                        {errors.shift}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <label>Enter Total Points</label>
                    <Field
                      type="number"
                      // style={{ height: "8rem" }}
                      value={values.total}
                      name="total"
                      component={Input}
                      placeholder="Enter Total Points"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <label>Enter Achieved Points</label>
                    <Field
                      type="number"
                      // style={{ height: "8rem" }}
                      value={values.achieved}
                      name="achieved"
                      component={Input}
                      placeholder="Enter Achieved Points"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Select Date</label>
                    <div>
                      <DatePickerField
                        name="date"
                        // label="Date"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  disabled={loading}
                  type="submit"
                  // onClick={() =>  handleSubmit(values)}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
