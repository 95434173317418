import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import { Modal, Spinner, Table } from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { AddDailyReports } from './AddDailyReports'
import DeleteModal from "../../Components/DeleteModal";
import { useHistory } from 'react-router-dom'
import UsePagination from "../../Components/UsePagination";



export function DailyReports() {

  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [dailyReport, setDailyReport] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [editItem, setEditItem] = useState({});
  const [userList, setUserList] = useState([])
  const [kpisList, setKpisList] = useState([])
  const [stationsList, setStationList] = useState([])
  const [links, setLinks] = useState([]);

  const authtoken = localStorage.getItem("userToken");

  const history = useHistory()




  useEffect(() => {
    DailyReports(`${baseUrl}/admin/daily-reports`);
    getAllUser();
    getKpis()
    getStations()
  }, []);

  const DailyReports = (url) => {
    setloading(true);
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        // console.log("brands List", data.payload);
        setDailyReport(data.payload.data);
        setLinks(data.payload.links)
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const getAllUser = async () => {
    await fetch(`${baseUrl}/admin/groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          let arr = []
          data.payload.forEach(item => {
            arr.push({ label: item.name, value: item.id })
          });
          setUserList(arr.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getKpis = () => {
    fetch(`${baseUrl}/admin/kpis`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let arr = []
        data.payload.forEach(item => {
          arr.push({ label: item.name, value: item.id })
        });
        setKpisList(arr.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStations = () => {
    fetch(`${baseUrl}/admin/stations`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let arr = []
        data.payload.forEach(item => {
          arr.push({ label: item.name, value: item.id })
        });
        setStationList(arr.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };




  const openEdit = (item) => {
    setEditItem(item);
    seteditModal(true);
    setaddModal(true);
  };

  const deleteItem = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };



  const onPaginate = (link) => {
    DailyReports(link.url);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

  };

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <>
          <CardHeader title="Daily Report list">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary mx-2"
                onClick={() => {
                  seteditModal(false);
                  setaddModal(true);
                }}
              >
                New Daily Report
              </button>
            </CardHeaderToolbar>
          </CardHeader>

          <CardBody>
            <Table responsive>
              <thead>
                <tr id="tbl" >
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    User Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    KPI's
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Sub Kpis
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Group
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Total Points
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Achieved Points
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Percentage
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Shift
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Edit
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  dailyReport?.map((sta, i) => (

                    <tr key={i} id="tbl" className='colorChange'>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.user?.name}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.sub_kpi?.kpi?.name}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.sub_kpi?.name}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.group}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.total_points}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.achieved_points}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.percentage + "%"}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.shift}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {sta?.date}
                      </td>

                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span
                          title="edit"
                          style={{ cursor: "pointer" }}
                          className="ml-1 svg-icon svg-icon-md svg-icon-primary"
                          onClick={() => {
                            openEdit(sta);
                          }}
                        >
                          <SVG
                            title="edit"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span
                          title="delete"
                          style={{ cursor: "pointer" }}
                          className="svg-icon svg-icon-md svg-icon-danger ml-4"
                          onClick={() => {
                            deleteItem(sta.id);
                          }}
                        >
                          <SVG
                            title="delete"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>


            <UsePagination links={links} onPaginate={onPaginate} />
          </CardBody>
        </>
      </Card>
      <Modal show={addModal} size="lg">
        <Modal.Header>
          <h4>{editModal ? "Edit Daily Report" : "Add Daily Report"}</h4>
        </Modal.Header>
        <AddDailyReports
          users={userList}
          kpis={kpisList}
          stations={stationsList}
          editItem={editModal ? editItem : ""}
          reload={DailyReports}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>

      <DeleteModal
        HideModal={() => setDeleteModal(false)}
        method="DELETE"
        reload={() => DailyReports()}
        visible={deleteModal}
        url={`${baseUrl}/admin/daily-reports/${selectedId}`}
      />
    </>
  );
}
