import { Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { Card, CardBody, CardHeader,CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { baseUrl } from '../../../services/config';
import { useEffect } from 'react';
import { useQuery } from "react-query";
import { toast } from 'react-hot-toast';
import DeleteModal from '../../Components/DeleteModal';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import firebase from "firebase";
import { notificationUrl } from '../../../firebase/config';
// import { database } from "../../../firebase/config";



const Complaints = () => {

  const authtoken = localStorage.getItem("userToken");
  const [selectedId,setSelectedId]=useState("")
  const [responseModal,setResponseModal]=useState(false)
  const [responseLoading,setresponseLoadig]=useState(false)
  const [responseText,setResponseText]=useState("")
  const [user,setUser]=useState([])
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusNumber, setStatusNumber] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [selectedObject,setSelectedObject]=useState({})
  const { isLoading: myLoading, error, data, refetch: reloadComplain } = useQuery("complains", () =>
    fetch(`${baseUrl}/admin/user-complaint`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    }).then((res) => res.json())
      .then((resp) => {
        return resp.payload.reverse()
      })
  );
 console.log(data)
//  console.log(data.user)
//  console.log(data.user.device_ids);
   const notisender = () => {
       const pushkey = firebase
         .database()
         .ref("notifications")
         .push().key;
       firebase
         .database()
         .ref("notifications")
         .child(selectedObject?.user.id)
         .child(pushkey)
         .set({
           description: responseText,
           title: "Admin send complain response",
           image:"",
           notificationId: pushkey,
            userName: selectedObject?.user?.name,
            userId: selectedObject?.user.id,
            timeStamp: Date.now(),
         });
         selectedObject.user.device_ids.map((ids) => {
         const notification = {
           title: "Admin send complain response",
           message:responseText ,
         };
         fetch(notificationUrl, {
           headers: {
             "Content-Type": "application/json",
            //  Authorization:
            //    "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4",
           },
           method: "POST",
           body: JSON.stringify({
             ...notification,
             deviceToken: ids,
           }),
         })
           .then((response) => response.json())
           .then((data) => {
             console.log("response", data);
           })
           .catch((err) => {
             console.log(err);
           });
       });

   };
console.log(selectedObject,"item")
  const formetDateTime = date => {

    let result = ''
    let fd = new Date(date)
    let newd = new Intl.DateTimeFormat('en-us', {
      dateStyle: 'full',
      timeStyle: 'medium'
    })
    result = newd.format(fd)
    return result
  }

  const updateStatus = () => {
    setUpdateStatusLoading(true)
    fetch(`${baseUrl}/admin/user-complaint-change-status`, {
      method: 'POST',
      
      headers: {
        // 'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({complaint_id: selectedId,status:statusNumber}),

    }).then(res => res.json())
      .then((data) => {
        console.log(data)
        setUpdateStatusLoading(false)

        if (data.metadata.success) {
          toast.success(data.metadata.message)
          setStatusModal(false)
          reloadComplain()
        } else if (!data.metadata.success) {
          Object.values(data.metadata.success).map((msg) => {
            toast.error(msg)
          setStatusModal(false)
          })
        }
      }).catch(err => {
        console.log(err)
        setUpdateStatusLoading(false)
      })
  }






  if (myLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner animation='border' />
      </div>
    )
  }

const sendResponse=(id)=>{
  setSelectedId(id)
  setResponseModal(true)
}

const responseSend=()=>{
    setresponseLoadig(true)
    const data = {
      response:responseText
    };
    fetch(`${baseUrl}/admin/complaint/${selectedId}/response`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.metadata){
          if(data.metadata.success){
            console.log(data);
            setresponseLoadig(false)
            toast.success(data.metadata.message)
            notisender()
            setResponseModal(false)
            reloadComplain()
          }else{
      toast.error(data.metadata.message)
      setResponseModal(false)
      setresponseLoadig(false)

          }
        }
        
      })
      .catch((err) => {
    setresponseLoadig(false)
        console.log("err is re", err);
      });
}
console.log(responseText)
const deleteItem = (id) => {
  setSelectedId(id);
  setDeleteModal(true);
};
const getLabelCssClasses = (comp) => {
  return `label label-lg label-light-${comp.status == 2 ? 'success' : comp.status == 3 ? 'danger' : 'info' } label-inline`;
};

  return (
    <>
      <Card>
        <>
          <CardHeader title="Complaints">
      
          </CardHeader>

          <CardBody>
            {
              myLoading && <div>
                <Spinner animation='border' />
              </div>
            }
            <Table responsive style={{width:"1000px"}}>
              <thead style={{ marginTop: "20px" }}>
                <tr id="tbl" >
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Title
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Complainer Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Date Time
                  </th>
                   <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Action
                  </th> 
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Delete
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Message
                  </th>

                </tr>
              </thead>
              <tbody>
                {
                  data.map((comp, i) => (
                    
                    <tr key={comp.id} id="tbl" className='colorChange'>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {comp.title}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {comp.description}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {comp?.user?.name}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {formetDateTime(comp.created_at)}
                      </td>
                      {/* <td
                        style={{
                          color: comp.approved == "1" ? 'green' : 'red',
                          // backgroundColor: comp.approved == "1" ? 'green' :'red',
                          textAlign: "center",
                        }}
                      >
                        {comp.approved == "1" ? 'Approved' : 'Rejected'}
                      </td> */}
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                          width:"300px"
                        }}
                      >
                     
               
                        <button onClick={() => {
                          setSelectedId(comp.id)
                          setStatusNumber(2)
                          setStatusModal(true)
                          
                          }} className='btn btn-primary'>Approve</button>
                        <button onClick={() => {
                          setSelectedId(comp.id)
                          setStatusNumber(3)
                          setStatusModal(true)
                          
                          }}className='btn btn-danger mx-2'>Reject</button>
                      
                        <button onClick={() => {
                          setSelectedObject(comp)
                          sendResponse(comp.id)}} className='btn btn-light mx-2'>Response</button>

                      
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                     
                      <span className={getLabelCssClasses(comp)}>{comp.status==1?"Pending":comp.status==2?"Approved":"Rejected"}</span>
                      </td>
                      <td>
                    <span
                    title='delete'
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger ml-4"
                      onClick={() => {
                        deleteItem(comp.id);
                      }}
                    >
                      <SVG
                       title='delete'
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                  <td
                   style={{
                    color: "black",
                    textAlign: "center",
                    width:"300px"
                  }}
                  >
                    <span>{comp.admin_response}</span>
                  </td>

                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </CardBody>
        </>
        <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={responseModal}
        onHide={()=>setResponseModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Send Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
             <div className='col-md-12'>
               <input onChange={(e)=>setResponseText(e.target.value)} placeholder='Enter Response' type='text' className='form-control'/>
             </div>
        </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={()=>setResponseModal(false)}>
            Close
          </Button>
          <Button disabled={responseLoading} variant="primary" onClick={responseSend}>Send</Button>
        </Modal.Footer>
      </Modal>
        <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={statusModal}
        onHide={()=>setStatusModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
             <div className='col-md-12'>
              <p>Are You Sure You Want To Update The Status</p>
             </div>
        </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={()=>setStatusModal(false)}>
            Close
          </Button>
          <Button disabled={updateStatusLoading} variant="primary" onClick={updateStatus}>Update</Button>
        </Modal.Footer>
      </Modal>

      <DeleteModal
        HideModal={() => setDeleteModal(false)}
        method="DELETE"
        reload={() => reloadComplain()}
        visible={deleteModal}
        url={`${baseUrl}/admin/user-complaint/${selectedId}`}
      />
      </Card>
    </>
  )
}

export default Complaints