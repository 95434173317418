import React,{useState} from "react";
import "./TotalOperator.css";
import Table from "react-bootstrap/Table";

function TotalOperator() {


  const [list,setlist]= useState([])


  const Pakistan = [
    {
      id: 1,
      Name: "Kamran",
      City: "Karachi",
      user: "Jazz",
      Number: "03242924997",
    },
    {
      id: 2,
      Name: "Ahmed Bhai",
      City: "Fasilabad",
      user: "Ufone",
      Number: "03322926577",
    },
    {
      id: 3,
      Name: "Junaid",
      City: "Jarawala",
      user: "Telenor",
      Number: "034629249667",
    },
    {
      id: 4,
      Name: "Tanveer",
      City: "Lahore",
      user: "Zong",
      Number: "031629265547",
    },
    {
      id: 5,
      Name: "Anees",
      City: "Karachi",
      user: "Jazz",
      Number: "03012534887",
    },
  ];

  const India = [
    {
      id: 1,
      Name: "Kumar",
      City: "Dehli",
      user: "Airtel",
      Number: "03643764384",
    },
    {
      id: 2,
      Name: "Kabel",
      City: "Bombay",
      user: "Reliance",
      Number: "035437678432",
    },
    {
      id: 3,
      Name: "Sharma",
      City: "Kalkata",
      user: "Vodafone",
      Number: "03767766552",
    },
    {
      id: 4,
      Name: "Yadeev",
      City: "New Dehli",
      user: "Reliance",
      Number: "03409754694",
    },
    {
      id: 5,
      Name: "Kholi",
      City: "Bombay",
      user: "Vodafone",
      Number: "03716574289",
    },
  ];
  const Saudia = [
    {
      id: 1,
      Name: "Sheikh",
      City: "Makkha",
      user: "jazz",
      Number: "098838243",
    },
    {
      id: 2,
      Name: "habibi",
      City: "Madina",
      user: "Ufone",
      Number: "34567436754",
    },
    {
      id: 3,
      Name: "Sabri",
      City: "jadha",
      user: "Telenor",
      Number: "45678543737",
    },
    {
      id: 4,
      Name: "Mujeeb",
      City: "Makkha",
      user: "Zong",
      Number: "3456807874",
    },
    {
      id: 5,
      Name: "Ali",
      City: "Madina",
      user: "Jazz",
      Number: "35643478654",
    },
  ];



  const HandleChange = (value)=>{
    if(value==""){
      setlist([])
    }
    else if(value=="Pakitan"){
      setlist(Pakistan)
    }
    else if(value=="India"){
      setlist(India)
    }
  }

  return (
    <div>
      <div className="wrapp">
        <div className="select">
          <select onChange={(e) => HandleChange(e.target.value)}>
            <option defaultChecked value="">Select Country</option>
            <option value="Pakitan">Pakitan</option>
            <option value="India">India</option>
            <option value="Iran">Iran</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Africa">Africa</option>
            <option value="Saudia">Saudia Arab</option>
          </select>
        </div>
        <div className="select ml-3">
          <select>
            <option value="Zong">Zong</option>
            <option value="Telenor">Telenor</option>
            <option value="Ufone">Ufone</option>
            <option value="Jazz">Jazz</option>
            <option value="Airtel">Airtel</option>
            <option value="Reliance">Reliance</option>
            <option value="Vodafone">Vodafone</option>
          </select>
        </div>
      </div>
      <Table responsive className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>NAME</th>
            <th>CITY</th>
            <th>USER</th>
            <th>NUMBER</th>
          </tr>
        </thead>
        {list.map((item, index) => (
          <tbody>
            <tr>
              <td>{item.id}</td>
              <td>{item.Name}</td>
              <td>{item.City}</td>
              <td>{item.user}</td>
              <td>{item.Number}</td>
            </tr>
          </tbody>
        ))}
      </Table>
    </div>
  );
}

export default TotalOperator;
