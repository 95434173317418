import React from "react";
import SVG from "react-inlinesvg";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "./ViewReportCard.css";

function ViewReportCard(props) {
  return (
    <div>
      {/* <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div
            class="card"
            style={{
              width: "325px",
              height: "290px",
              margin: "8px",
              background: "#091125",
            }}
          >
            <div class="card-body">
              <h3 class="mb-5" style={{ color: "#BC021D" }}>
                Title
              </h3>
              <p class="card-subtitle mb-2 text-color">{props.item?.title}</p>
              <h3 class="mb-5" style={{ color: "#BC021D" }}>
                Description
              </h3>
              <p class="card-subtitle mb-2 text-color">
                {props.item?.description}
              </p>
              <h3 class="mb-5" style={{ color: "#BC021D" }}>
                URL
              </h3>
              <p class="card-subtitle mb-2 text-color">{props.item?.url}</p> */}
      {/* <p class="card-text">It is the seventh most populous city in Italy, at the heart of a metropolitan area of about one million people. </p> */}
      {/* <div
                style={{ display: "flex", justifyContent: "space-around" }}
                className="mt-5 "
              >
                <div style={{ alignSelf: "flex-end" }}>
                  <span
                    onClick={() => {
                      console.log(props.item);
                      props.selecteditem(props.item);
                      props.show(true);
                    }}
                    className="svg-icon svg-icon-md svg-icon-primary paositionOfIcon"
                    style={{ cursor: "pointer" }}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Devices/Display2.svg"
                      )}
                    />
                  </span>
                </div>

                <div>
                  <span
                   onClick={() => {
                      props.selecteditem(props.item);
                      props.editmodal(props.item);
                    }}
                    className="ml-1 svg-icon svg-icon-md svg-icon-primary paositionOfIcon"
                    style={{ cursor: "pointer" }}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </span>
                </div>
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    className="svg-icon svg-icon-md svg-icon-danger paositionOfIcon"
                    style={{ color: "red", size: "80" }}
                    onClick={() => {
                      props.selecteditem(props.item);
                      props.showdeletemodal(true);
                    }}
                  >
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* table */}
    </div>
  );
}

export default ViewReportCard;
