import axios from "axios";
import { baseUrl } from "../../../../services/config";
export const LOGIN_URL = `${baseUrl}/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

const device_id = "14asd5fa2s3d";

export async function login(email, password) {
  console.log(email,password)
  const user = {
    email: "admin@system.com",
    password: "password",
    device_id:"eYQSnnJ-yabzpfppxDOeex:APA91bGnOwCxTwsipthNLZo8VWZp6CWJvZtyVtgcy4M64cFg1uGPSzw2AKtY1oWHEn4-UwEdzxT6YRy21WcpfsQBst8VTI-WccBFHRcG5ir41TXUbCvW0OTTIiI2FLln02Lizrprtb2Z",
  };
  console.log(user)
  return   await axios.post(`http://portal.iredhawk.com/api/login`, user)
  // fetch(`${baseUrl}/login`, {
  //   method: "POST",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     // 'X-Requested-With': 'XMLHttpRequest'
  //   },
  //   body: JSON.stringify(user),
  // });

  // await axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
