
import firebase from "firebase/app";
import "firebase/database";


export const push_key = 'BD1FlpHCm3-sgFZd9n4AFsWwLPnPKWqz5JSiGhlvCk92eganOnQcF15p_xV52X71Bc4ChClKJH92j5nubut8s5s'
// export const push_key = 'BHrvqgclKNmluNyuzrjI8V2laWKI5jD_QzSdyNkZWXT2t__oJJdP4oeKFlSNpeBJ0-CjPjmTJjluz-zSaZudKhE'

export const firebaseConfig = {
  apiKey: "AIzaSyCqkv2Ir7fFezktArAJHdAy7u4UxVHhK_4",
  authDomain: "redhawk-2d1e0.firebaseapp.com",
  databaseURL: "https://redhawk-2d1e0-default-rtdb.firebaseio.com",
  projectId: "redhawk-2d1e0",
  storageBucket: "redhawk-2d1e0.appspot.com",
  messagingSenderId: "355105458070",
  appId: "1:355105458070:web:3e98a783b5f3c68bfabfb7",
  measurementId: "G-D4TTHM2DFM"
};
// export const firebaseConfig = {
//   apiKey: "AIzaSyC7tv6B_blid8jzxwD3GB6UhX44jq9tKDc",
//   authDomain: "dummy-317a9.firebaseapp.com",
//   projectId: "dummy-317a9",
//   storageBucket: "dummy-317a9.appspot.com",
//   messagingSenderId: "227516050779",
//   appId: "1:227516050779:web:1102a01e1ea76f050a3e7e",
//   measurementId: "G-PKQ6TPHHV1"
// };


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}





export const getTokenfirebase = (setTokenFound) => {
  return firebase.messaging().getToken({ vapidKey: push_key }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}



export const onMessageListener = () =>
  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload);
    });
  });


  export const firebaseAuth = firebase.auth();

  export const googleAuthProvider  = new firebase.auth.GoogleAuthProvider()
  export const database = firebase.database();

  export const notificationUrl="https://redhawk-fcm-node-js.vercel.app/token/notifications"