/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { baseUrl } from "../../../../../services/config";
import { useDispatch } from "react-redux";

export function UserProfileDropdown() {
  // const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const authtoken = localStorage.getItem("userToken");
  const logoutClick = () => {
    localStorage.clear();
    window.location.href = '/auth/login'
    // history.push('/auth/login')
    // history.push("/auth/login");
    // const fuser = {
    //   device_id: "14asd5fa2s3d",
    // };
    // fetch(`${baseUrl}/logout`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${authtoken}`,
    //     // 'X-Requested-With': 'XMLHttpRequest'
    //   },
    //   body: JSON.stringify(fuser),
    // })
    //   .then((resp) => {
    //     resp.json().then((user) => {
    //       console.log(user);
    //       if (user.metadata.success) {
    //         dispatch({
    //           type: "ADD_USER",
    //           payload: {
    //             isAuthenticated: false,
    //             authtoken: null,
    //             user: null,
    //           },
    //         });
    //         localStorage.clear();
    //         history.push("/auth/login");
    //       } else if (!user.data.success) {
    //         alert('session destroy loging out..')
    //         localStorage.clear();
    //         history.push("/auth/login");
    //       }

    //     });
    //   })

    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (

    <div className="navi-footer  px-8 py-5">
      <button
        className="btn btn-danger font-weight-bold"
        onClick={logoutClick}
      >
        Sign Out
      </button>
      {/* <a href="#" className="btn btn-clean font-weight-bold">
      Upgrade Plan
    </a> */}
    </div>





  );
}
