// import { Paper } from '@material-ui/core'
// import React, { useState } from 'react'
// import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
// import { Modal, Spinner, Table } from 'react-bootstrap';
// import { baseUrl } from '../../../services/config';
// import { useEffect } from 'react';
// import { useQuery } from "react-query";
// import { toast } from 'react-hot-toast';
// import AddNotificationAlert from './AddNotificationAlert';
// import DeleteModal from '../../Components/DeleteModal';
// import EditNotificationAlert from './EditNotificationAlert';

// const NotificationsAlerts = () => {

//   const authtoken = localStorage.getItem("userToken");
//   const [addOnModal, setAddOnModal] = useState(false)
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [editModal, seteditModal] = useState(false);
//   const [selectedId, setSelectedId] = useState("");
//   const { isLoading: myLoading, error, data, refetch: reloadComplain } = useQuery("notificationsAlerts", () =>
//     fetch(`${baseUrl}/admin/notifications`, {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${authtoken}`,
//       }
//     }).then((res) => res.json())
//       .then((resp) => {
//         return resp.payload
//       })
//   );

//   const formetDateTime = date => {

//     let result = ''
//     let fd = new Date(date)
//     let newd = new Intl.DateTimeFormat('en-us', {
//       dateStyle: 'full',
//       timeStyle: 'medium'
//     })
//     result = newd.format(fd)
//     return result
//   }

//   if (myLoading) {
//     return (
//       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//         <Spinner animation='border' />
//       </div>
//     )
//   }

//   const handleAddClose = () => {
//     setAddOnModal(false)
//   }
//   const handleEditClose = () => {
//     seteditModal(false)
//   }
//   const deleteItem = (id) => {
//     setSelectedId(id);
//     setDeleteModal(true);
//   };
//   const editItem = (item) => {
//     setSelectedId(item);
//     seteditModal(true);
//   };

//   return (
//     <>
//       <Card>
//         <>
//           <CardHeader title="Announcement">
//             <CardHeaderToolbar>
//               <button
//                 onClick={() => setAddOnModal(true)}
//                 type="button"
//                 className="btn btn-primary mx-2"
//               >
//                 Announcement
//               </button>
//             </CardHeaderToolbar>
//           </CardHeader>

//           <CardBody>
//             {
//               myLoading && <div>
//                 <Spinner animation='border' />
//               </div>
//             }
//             <Table responsive>
//               <thead style={{ marginTop: "20px" }}>
//                 <tr id="tbl" >
//                   <th
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     Sr.no
//                   </th>
//                   <th
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     Title
//                   </th>
//                   <th
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     Description
//                   </th>

//                   <th
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     Action
//                   </th>

//                 </tr>
//               </thead>
//               <tbody>
//                 {
//                   data?.map((comp, i) => (

//                     <tr key={comp.id} id="tbl" className='colorChange'>
//                       <td
//                         style={{
//                           color: "black",
//                           textAlign: "center",
//                         }}
//                       >
//                         {i + 1}
//                       </td>
//                       <td
//                         style={{
//                           color: "black",
//                           textAlign: "center",
//                         }}
//                       >
//                         {comp.title}
//                       </td>

//                       <td
//                         style={{
//                           // backgroundColor: comp.approved == "1" ? 'green' :'red',
//                           textAlign: "center",
//                         }}
//                       >
//                         {comp.description}
//                       </td>
//                       <td
//                         style={{
//                           color: "black",
//                           textAlign: "center",
//                         }}
//                       >
//                         <div className='d-flex justify-content-center'>

//                           <button className='btn btn-info mx-2' onClick={() => editItem(comp)}>Edit</button>

//                           <button className='btn btn-danger' onClick={() => deleteItem(comp.id)}>Delete</button>

//                         </div>
//                       </td>

//                     </tr>
//                   ))
//                 }
//               </tbody>
//             </Table>
//           </CardBody>
//         </>
//       </Card>
//       <Modal show={addOnModal} onHide={handleAddClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add Announcement</Modal.Title>
//         </Modal.Header>
//         <AddNotificationAlert onHide={handleAddClose} reload={reloadComplain} />
//       </Modal>
//       <Modal show={editModal} onHide={handleAddClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Announcement</Modal.Title>
//         </Modal.Header>
//         <EditNotificationAlert onHide={handleEditClose} reload={reloadComplain} selectedItem={selectedId} />
//       </Modal>
//       <DeleteModal
//         HideModal={() => setDeleteModal(false)}
//         method="DELETE"
//         reload={reloadComplain}
//         visible={deleteModal}
//         url={`${baseUrl}/admin/notifications/${selectedId}`}
//       />
//     </>
//   )
// }

// export default NotificationsAlerts
import React, { useEffect, useState } from "react";
import {
  CardActions,
  CardContent,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import DeleteModel from '../../Components/DeleteModel/DeleteModel';
import DeleteModal from "../../Components/DeleteModal";
import firebase from "firebase";
import { baseUrl } from '../../../services/config';
import { toast } from "react-hot-toast";
import AddNotificationAlert from "./AddNotificationAlert";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import "./Notification.css";
import SVG from "react-inlinesvg";
import { Button as Butter } from "antd";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
const authtoken = localStorage.getItem("userToken");
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    color: "black",
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const NotificationsAlerts = () => {
  const classes = useStyles();

  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [announcement, setAnnouncement] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [editItem, setEditItem] = useState({});
  const [users, setAllUsers] = useState([]);
  const history = useHistory();
  const [userDetails, setuserDetail] = useState({});
  const [UsersList, setUsersList] = useState([]);



  useEffect(() => {
    getAnnoucment();
    getAllUser()
  }, []);
  const getAllUser = async () => {
    await fetch(`${baseUrl}/admin/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setUsersList(data.payload.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAnnoucment = () => {
    setloading(true);
    fetch(`${baseUrl}/admin/notifications`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        console.log("brands List", data.payload);
        setAnnouncement(data.payload.reverse());
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const onDeleteClick = (id) => {
    setSelectedId(id);
    setDeleteModal(true);
  };

  const onEditClick = (item) => {
    setEditItem(item);
    seteditModal(true);
    setaddModal(true);
  };
 console.log(announcement)
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <CardHeader title="Announcements">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                seteditModal(false);
                setaddModal(true);
              }}
            >
              Add Announcement
            </button>
  
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            {announcement.map((item) => (
              <div className="col-lg-6">
                <div className="annousmentCardCont">
                  <div className="annousmentCard">
                    <div className="announcementImage">
                      <img src={item.image?.original_url} />
                    </div>
                    <div className="anouncementTitle">
                      <div>
                        <h3>{item.title}</h3>
                      </div>
                      <div>
                        <p>{item.description}</p>
                      </div>
                      <div className="annoutbutton">
                      <a
                        title="Edit"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
                        onClick={() => onEditClick(item)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Edit"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                      <a
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={() => onDeleteClick(item.id)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            title="Delete"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </a>
                    </div>
                    {userDetails.type == "Admin" ? (
                      <div className="annoutbutton">
                        <a
                          title="Edit"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
                          onClick={() => onEditClick(item)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              title="Edit"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                        <a
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => onDeleteClick(item.id)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              title="Delete"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </a>
                      </div>
                    ) : null}
                    </div>
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>

      <Modal centered show={addModal}>
        <Modal.Header>
          <h4>{editModal ? "Edit Announcement" : "Add Announcement"}</h4>
        </Modal.Header>
        <AddNotificationAlert
          users={users}
          currentUserData={userDetails}
          editItem={editModal ? editItem : ""}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => console.log(values)}
          onHide={() => setaddModal(false)}
          reload={() => getAnnoucment()}
          UsersList={UsersList}
        />
      </Modal>
        <DeleteModal
        HideModal={() => setDeleteModal(false)}
        method="DELETE"
        reload={() => getAnnoucment()}
        visible={deleteModal}
        url={`${baseUrl}/admin/notifications/${selectedId}`}
      />
    </>
  );
};

export default NotificationsAlerts;
